import React, { useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { equals } from 'ramda';
import { isNilOrEmpty } from 'ramda-extension';
import { Box, Flex } from 'rebass';
import { Input } from '@rebass/forms';

import { textFieldFocusMode } from './constants';
import { FormErrorText } from './FormErrorText';

const equalsFocusModeWhole = equals(textFieldFocusMode.WHOLE);

export const TextField = ({
	disabled,
	prefixElement,
	suffixElement,
	focusMode = textFieldFocusMode.WHOLE,
	register,
	wrapperSx = {},
	sx,
	errorMessage,
	variant = 'primary',
	width,
	autoFocus,
	autoFocusWithScroll,
	inputElement: InputElement,
	languageCode,
	...other
}) => {
	const inputRef = useRef(null);
	const handleFocus = useCallback(() => (equalsFocusModeWhole(focusMode) ? inputRef.current?.focus?.() : null), [
		inputRef,
		focusMode,
	]);

	useEffect(() => {
		if ((isNilOrEmpty(other.value) && autoFocus) || (autoFocus && autoFocusWithScroll)) {
			// eslint-disable-next-line no-unused-expressions
			inputRef.current?.focus?.();
			if (autoFocus && autoFocusWithScroll) {
				// eslint-disable-next-line no-unused-expressions
				inputRef.current?.scrollIntoView(true);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [autoFocus]);

	const registerRef = useCallback(
		(ref) => {
			inputRef.current = ref;
			if (register != null) {
				register(ref);
			}
		},
		[register]
	);
	return (
		<FormErrorText errorMessage={errorMessage}>
			<Box
				onClick={handleFocus}
				bg={disabled ? 'disabled' : 'white'}
				sx={{
					height: '40px',
					borderRadius: 3,
					cursor: 'text',
					border: '1px solid',
					position: 'relative',
					variant: `forms.fields.textField.${variant}`,
				}}
				width={width}
			>
				<Flex alignItems="center" height="100%">
					{prefixElement}
					{InputElement ? (
						<InputElement
							sx={{
								outline: 'none',
								border: 'none',
								fontSize: 1,
								...sx,
							}}
							ref={registerRef}
							languageCode={languageCode}
							disabled={disabled}
							{...other}
						/>
					) : (
						<Input
							sx={{
								outline: 'none',
								border: 'none',
								fontSize: 1,
								...sx,
							}}
							ref={registerRef}
							disabled={disabled}
							{...other}
						/>
					)}
					{suffixElement}
				</Flex>
			</Box>
		</FormErrorText>
	);
};

TextField.propTypes = {
	autoFocus: PropTypes.bool,
	autoFocusWithScroll: PropTypes.bool,
	disabled: PropTypes.bool,
	errorMessage: PropTypes.string,
	focusMode: PropTypes.oneOf([textFieldFocusMode.INPUT, textFieldFocusMode.WHOLE]),
	inputElement: PropTypes.element,
	languageCode: PropTypes.string,
	prefixElement: PropTypes.element,
	register: PropTypes.func,
	suffixElement: PropTypes.element,
	sx: PropTypes.object,
	variant: PropTypes.string,
	width: PropTypes.string,
	wrapperSx: PropTypes.object,
};
