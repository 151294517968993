export const languageLabels = {
	ar: 'Arabština',
	ba: 'Bashkir',
	be: 'Běloruština',
	bg: 'Bulharština',
	cs: 'Čeština',
	da: 'Dánština',
	de: 'Němčina',
	el: 'Řečtina',
	en: 'Angličtina',
	es: 'Španělština',
	et: 'Estonština',
	fi: 'Finština',
	fr: 'Francouzština',
	ga: 'Irština',
	he: 'Hebrejština',
	ho: 'Hiri Motu',
	hr: 'Chorvatština',
	hu: 'Maďarština',
	id: 'Indonéština',
	is: 'Islandština',
	it: 'Italština',
	ja: 'Japonština',
	ka: 'Gruzinština',
	kn: 'Kannadština',
	ko: 'Korejština',
	la: 'Latinština',
	lb: 'Lucemburština',
	lt: 'Litevština',
	lv: 'Lotyština',
	nl: 'Holandština',
	no: 'Norština',
	pl: 'Polština',
	pt: 'Portugalština',
	ro: 'Rumunština',
	ru: 'Ruština',
	sl: 'Slovinština',
	sr: 'Srbština',
	sv: 'Švédština',
	tr: 'Turečtina',
	vi: 'Vietnamština',
	zh: 'Čínština',
	aa: 'Afar',
	ab: 'Abcházce',
	ae: 'Avestan',
	af: 'Afrikánština',
	ak: 'Akan',
	am: 'Amharština',
	an: 'Aragonština',
	as: 'Asámština',
	av: 'Avaric',
	ay: 'Aymara',
	az: 'Ázerbájdžánština',
	bh: 'Bihari jazyky',
	bi: 'Bislama',
	bm: 'Bambara',
	bn: 'bengálský',
	bo: 'Tibetský',
	br: 'Breton',
	bs: 'Bosenské',
	ca: 'Katalánština; Valencie',
	ce: 'Čečenec',
	ch: 'Chamorro',
	co: 'Korsičan',
	cr: 'Cree',
	cu: 'Církevní slovanština; Staroslovanština; Církevní slovanština; Starou bulharštinu; Staroslověnština',
	cv: 'Chuvash',
	cy: 'Velština',
	dv: 'Divehi; Dhivehi; Maledivština',
	dz: 'Dzongkha',
	ee: 'Ewe',
	eo: 'Esperanto',
	eu: 'Baskičtina',
	fa: 'Peršan',
	ff: 'Fulah',
	fj: 'Fidžijský',
	fo: 'Faerský',
	fy: 'Západní Fríština',
	gd: 'Gaelština; Skotská gaelština',
	gl: 'Galicijština',
	gn: 'Guarani',
	gu: 'Gudžarátština',
	gv: 'Manský',
	ha: 'Hausa',
	hi: 'Hindština',
	ht: 'Haitský; Haitská kreolština',
	hy: 'Arménský',
	hz: 'Herero',
	ia: 'Interlingua (Mezinárodní asociace pomocných jazyků)',
	ie: 'Interlingue; Západní',
	ig: 'Igbo',
	ii: 'Sichuan Yi; Nuosu',
	ik: 'Inupiaq',
	io: 'Ido',
	iu: 'Inuktitut',
	jv: 'Jávský',
	kg: 'Kongo',
	ki: 'Kikuyu; Gikuyu',
	kj: 'Kuanyama; Kwanyama',
	kk: 'Kazašský',
	kl: 'Kalaallisut; Grónský',
	km: 'Střední Khmer',
	kr: 'Kanuri',
	ks: 'Kašmír',
	ku: 'Kurdština',
	kv: 'Komi',
	kw: 'cornwallský',
	ky: 'Kirghiz; Kyrgyzština',
	lg: 'Ganda',
	li: 'Limburgan; Limburger; Limburský',
	ln: 'Lingalština',
	lo: 'Lao',
	lu: 'Luba-Katanga',
	mg: 'Malgaština',
	mh: 'Marshallese',
	mi: 'Maori',
	mk: 'Makedonština',
	ml: 'Malayalam',
	mn: 'Mongolský',
	mr: 'Maráthština',
	ms: 'Malajština',
	mt: 'Maltština',
	my: 'Barmská',
	na: 'Nauru',
	nb: 'Bokmål, norština; Norský Bokmål',
	nd: 'Ndebele, severní; Severní Ndebele',
	ne: 'Nepálština',
	ng: 'Ndonga',
	nn: 'Norský Nynorsk; Nynorsk, norština',
	nr: 'Ndebele, jih; Jižní Ndebele',
	nv: 'Navajo; Navaho',
	ny: 'Chichewa; Chewa; Nyanja',
	oc: 'Occitan (po roce 1500); Provensálské',
	oj: 'Ojibwa',
	om: 'Oromo',
	or: 'Oriya',
	os: 'Osetie; Osetický',
	pa: 'Panjabi; Pandžábský',
	pi: 'Pali',
	ps: 'Pushto; Paštštino',
	qu: 'Kečuánština',
	rm: 'Romansh',
	rn: 'Rundi',
	rw: 'Kinyarwanda',
	sa: 'Sanskrt',
	sc: 'Sardinský',
	sd: 'Sindhi',
	se: 'Severní Sami',
	sg: 'Sango',
	si: 'Sinhálština; Sinhálština',
	sk: 'Slovenština',
	sm: 'Samoan',
	sn: 'Shona',
	so: 'Somálci',
	sq: 'Albánec',
	ss: 'Swati',
	st: 'Sotho, jižní',
	su: 'Sundanština',
	sw: 'Svahilština',
	ta: 'Tamil',
	te: 'Telugština',
	tg: 'Tádžické',
	th: 'Thajština',
	ti: 'Tigrinya',
	tk: 'Turkmenština',
	tl: 'Tagalog',
	tn: 'Tswana',
	to: 'Tonga (Tonga Islands)',
	ts: 'Tsonga',
	tt: 'Tatar',
	tw: 'Twi',
	ty: 'Tahitian',
	ug: 'Ujgurština; Ujgur',
	uk: 'Ukrajinština',
	ur: 'Urdu',
	uz: 'Uzbek',
	ve: 'Venda',
	vo: 'Volapük',
	wa: 'Valonský',
	wo: 'Wolof',
	xh: 'Xhosa',
	yi: 'Jidiš',
	yo: 'Yoruba',
	za: 'Zhuang; Chuang',
	zu: 'Zulu',
};

export const wordTypesLabels = {
	noun: 'Podstatné jméno',
	adjective: 'Přídavné jméno',
	pronoun: 'Zájmena',
	numeral: 'Číslovky',
	verb: 'Sloveso',
	adverb: 'Příslovce',
	preposition: 'Předložky',
	conjunction: 'Spojky',
	determiner: 'Částice',
	exclamation: 'Citoslovce',
};
