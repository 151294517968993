import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { FormAlert } from './FormAlert';
import { useGetSectionNamesWithErrors } from '../../features/formSectionError';

export const ControlledFormErrorAlert = ({
	hasAnyErrors,
	errorTitleBuilder,
	errorMessageBuilder,
	message,
	title,
	...other
}) => {
	const [isVisible, setIsVisible] = useState(false);
	const getErrorSectionNames = useGetSectionNamesWithErrors();
	useEffect(() => {
		setIsVisible(hasAnyErrors);
		setTimeout(() => {
			setIsVisible(false);
		}, 5000);
	}, [hasAnyErrors]);

	return (
		<FormAlert
			title={errorTitleBuilder ? errorTitleBuilder(getErrorSectionNames()) : title}
			isVisible={isVisible}
			onClose={(/* EVENT */) => setIsVisible(false)}
			message={errorMessageBuilder ? errorMessageBuilder(getErrorSectionNames()) : message}
			{...other}
		/>
	);
};

ControlledFormErrorAlert.propTypes = {
	errorMessageBuilder: PropTypes.func,
	errorTitleBuilder: PropTypes.func,
	hasAnyErrors: PropTypes.bool,
	message: PropTypes.string,
	title: PropTypes.string,
};
