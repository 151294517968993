import { useCallback, useContext, useEffect } from 'react';
import { applyIfNotNil, isNilOrEmpty } from 'ramda-extension';

import { FormErrorContext, FormSectionContext } from './context';
import { emptyObject } from '../../constants';

export const useSafeFormSectionContext = () => {
	const formSectionContext = useContext(FormSectionContext);
	return formSectionContext ?? emptyObject;
};

export const useSafeFormErrorContext = () => {
	const formErrorContext = useContext(FormErrorContext);
	return formErrorContext ?? emptyObject;
};

export const useSetErrorForSection = () => {
	const { setHasError } = useSafeFormSectionContext();
	return useCallback((...args) => applyIfNotNil(setHasError, args), [setHasError]);
};

export const useSetErrorForSectionEffect = (hasError, name) => {
	const { setHasError } = useSafeFormSectionContext();
	useEffect(() => {
		applyIfNotNil(setHasError, [hasError, name]);
	}, [hasError, name, setHasError]);
};

export const useHasSectionErrors = (sectionName) => {
	const { sectionErrors } = useSafeFormErrorContext();
	return sectionErrors?.[sectionName] ?? false;
};

export const useGetSectionNamesWithErrors = () => {
	const { getErrorSectionNames } = useSafeFormErrorContext();
	return useCallback(() => getErrorSectionNames(), [getErrorSectionNames]);
};

export const useCommonErrorMessageBuilder = () =>
	useCallback((sectionNames) => {
		if (isNilOrEmpty(sectionNames)) {
			return 'Je nám to líto, ale formulář obsahuje chyby. Pro uložení je prosím opravte a zkuste kartičku uložit znovu.';
		}
		const sectionNamesString = sectionNames.map((sectionName) => `"${sectionName}"`).join(' a ');
		return `Je nám to líto, ale formulář obsahuje chyby na žáložce ${sectionNamesString}. Pro uložení je prosím opravte a zkuste kartičku uložit znovu.`;
	}, []);

export const useCommonErrorTitleBuilder = () =>
	useCallback((sectionNames) => {
		if (isNilOrEmpty(sectionNames)) {
			return 'Formulář obsahuje chyby';
		}
		const sectionNamesString = sectionNames.map((sectionName) => `“${sectionName}”`).join(' a ');
		return `Formulář obsahuje chyby v záložce ${sectionNamesString}`;
	}, []);
