import React, { forwardRef, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { omit } from 'ramda';
import { isNilOrEmpty, noop } from 'ramda-extension';
import { Box, Text } from 'rebass';
import { Input } from '@rebass/forms';
import { v1 } from 'uuid';

import { DictionaryWord } from './DictionaryWord';
import { DictionaryContextMenu } from './DictionaryContextMenu';
import { useRerender } from '../hooks/useRerender';
import { useDictionaryForLanguage } from '../hooks/useDictiornaryForLanguage';
import { emptyObject } from '../../../constants';
import { getSingleSanitizedWordsArray, getSingleWordsArray } from '../utils/wordSanitization';

export const DictionaryInput = forwardRef(
	(
		{
			value = '',
			placeholder,
			onChange,
			onBlur = noop,
			onFocus = noop,
			sx = emptyObject,
			languageCode: languageCodeProp,
			actualLanguageCode,
			disabled,
			...other
		},
		ref
	) => {
		const languageCode = actualLanguageCode ?? languageCodeProp;
		const boxRef = useRef();
		const currentValuePointer = useRef(value);
		const lastValuePointer = useRef();
		const [menuId] = useState(v1());
		const [boxId] = useState(v1());
		const [key, rerender] = useRerender();
		const [boxValue, setBoxValue] = useState(value);
		const [behaviourSubjectRef, requestSpellingCheck, setSpellingData] = useDictionaryForLanguage(languageCode);
		const handleInputChange = (event) => {
			const updatedValue = event.target.textContent.replace(placeholder, '');
			lastValuePointer.current = currentValuePointer.current;
			currentValuePointer.current = updatedValue;
			return onChange(updatedValue);
		};
		const words = useMemo(() => getSingleWordsArray(boxValue), [boxValue]);
		useEffect(() => {
			if (value !== currentValuePointer.current) {
				setBoxValue(value.trim());
			}
		}, [value]);

		const registerRef = useCallback(
			(newRef) => {
				boxRef.current = newRef;
				if (ref instanceof Function) {
					ref(newRef);
				} else {
					ref.current = newRef;
				}
			},
			[ref]
		);

		return (
			<Box
				sx={{
					display: 'block',
					outline: 'none',
					border: 'none',
					fontSize: 1,
					width: '100%',
					position: 'relative',
				}}
			>
				{isNilOrEmpty(value) && isNilOrEmpty(boxValue) ? (
					<Text
						p={2}
						onClick={(/* EVENT */) => boxRef.current.focus()}
						sx={{
							position: 'absolute',
						}}
						color="gray.300"
					>
						{placeholder}
					</Text>
				) : null}
				<Box
					key={key}
					id={boxId}
					ref={registerRef}
					onClick={(/* EVENT */) => boxRef.current.focus()}
					onBlur={(e) => {
						onBlur(e);
						rerender();
						setBoxValue(value);
						requestSpellingCheck(getSingleSanitizedWordsArray(value));
					}}
					onFocus={onFocus}
					spellCheck={false}
					onInput={handleInputChange}
					contentEditable={!disabled}
					suppressContentEditableWarning
					p={2}
					sx={{
						display: 'block',
						outline: 'none',
						border: 'none',
						fontSize: 1,
						width: '100%',
						whiteSpace: 'nowrap',
						overflow: 'hidden',
						'& br': {
							display: 'none',
						},
						'& *': {
							display: 'inline',
							whiteSpace: 'nowrap',
						},
						...sx,
					}}
				>
					{words.map((value, index) => (
						<DictionaryWord
							key={index}
							id={menuId}
							languageCode={languageCode}
							isLast={words.length - 1 === index}
							behaviourSubjectRef={behaviourSubjectRef}
						>
							{value}
						</DictionaryWord>
					))}
				</Box>
				<DictionaryContextMenu
					id={menuId}
					rerender={(word) => {
						setSpellingData(omit([word]));
						rerender();
					}}
				/>
				<Input
					value={value}
					disabled={disabled}
					sx={{
						display: 'none',
						visibility: 'hidden',
						outline: 'none',
						border: 'none',
						fontSize: 1,
						width: '100%',
					}}
					{...other}
				/>
			</Box>
		);
	}
);

DictionaryInput.propTypes = {
	actualLanguageCode: PropTypes.string,
	disabled: PropTypes.bool,
	languageCode: PropTypes.string,
	onBlur: PropTypes.func,
	onChange: PropTypes.func,
	onFocus: PropTypes.func,
	placeholder: PropTypes.string,
	sx: PropTypes.object,
	value: PropTypes.string,
};
