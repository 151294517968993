import { prop } from 'ramda';
import { defaultToEmptyObject } from 'ramda-extension';
import { createSelector } from 'reselect';

import { APP_CONTEXT_REDUCER_NAME } from './constants';

/**
 * getAppContext(state);
 */
export const getAppContext = createSelector(prop(APP_CONTEXT_REDUCER_NAME), defaultToEmptyObject);
