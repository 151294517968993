import axios from 'axios';

import { TAGS } from '../../../constants/urls';
import { createPageableRequest, dataP } from '../../../utils';

export const getPageableTags = createPageableRequest(TAGS);

export const createTag = (tag) => axios.post(TAGS, tag).then(dataP);

export const createTagFromName = (name) => axios.post(TAGS, { name }).then(dataP);

export const deleteTag = (tag) => axios.delete(`${TAGS}/${tag.id}`);

export const editTag = (tag) => axios.put(`${TAGS}/${tag.id}`, { tag }).then(dataP);
