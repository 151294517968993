import React from 'react';
import PropTypes from 'prop-types';
import { isNotNil } from 'ramda-extension';
import { Box } from 'rebass';
import { useFormContext } from 'react-hook-form';
import get from 'lodash.get';

import { useSetErrorForSectionEffect } from '../../features/formSectionError';

export const InvisibleSimpleUncontrolledField = ({ rules, name, ...other }) => {
	const { register, errors } = useFormContext();
	const fieldError = get(errors, name);
	const hasErrors = isNotNil(fieldError);
	useSetErrorForSectionEffect(hasErrors, name);
	return (
		<Box sx={{ display: 'none' }}>
			<input name={name} ref={register(rules)} {...other} />
		</Box>
	);
};

InvisibleSimpleUncontrolledField.propTypes = {
	name: PropTypes.string,
	rules: PropTypes.object,
};
