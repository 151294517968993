import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { compose, dissoc } from 'ramda';
import { isNotEmpty } from 'ramda-extension';
import { FormProvider } from 'react-hook-form';
import { Box, Flex } from 'rebass';
import deepFilter from 'deep-filter';

import { FormDirtyContext, FormSubmitContext, useConfirmBeforeLeave, useFormState } from './hooks';
import { Button } from '../../components/Button';
import { FormLoader } from '../../components/Loaders';
import { ControlledFormErrorAlert } from '../../components/Forms';
import { isNotNilOrEmpty } from '../../utils';
import { TextbookCreatorBottomBar } from '../../pages/TextbookCreator/components/TextbookCreatorBottomBar';
import { useNavigationWithState } from '../../features/locationState/locationContext';

const formStyle = { height: '100%', overflow: 'auto', display: 'flex', flexDirection: 'column' };

export const Form = ({
	bg = 'menuBackground',
	px,
	pt,
	pb,
	children,
	onSubmit,
	formName,
	hideSubmit,
	tabIndex = 0,
	errorMessage,
	hideAlert,
	hideErrorAlert,
	backButtonLabel,
	backStepButtonAction,
	backStepButtonLabel,
	backStepButtonDisableSubmit = false,
	hideBackButton = true,
	hideBackStepButton = true,
	hideBottomBar = true,
	hideSuccessAlert,
	submitCallback,
	submitMessages,
	errorMessageBuilder,
	errorTitleBuilder,
	submitButtonStyle = {},
	disableConfirmBeforeLeave,
	formButtonLabel = 'Uložit',
	stepper,
	publishButton,
	...formMethods
}) => {
	const { handleSubmit, errors, formState, reset } = formMethods;
	const [isDirty, setIsDirty] = useState(false);
	const navigate = useNavigationWithState();
	useConfirmBeforeLeave(isFormStateDirty(formState) || isDirty, disableConfirmBeforeLeave, isNotEmpty(errors));
	const { stateMessage, onSubmitWithMessage, isSending } = useFormState({
		onSubmit,
		tabIndex,
		hideSuccessAlert,
		hideErrorAlert,
		submitMessages,
		reset,
		setIsDirty,
	});
	const formSubmit = useCallback(
		(e) =>
			new Promise((resolve, reject) =>
				handleSubmit(
					async (...args) => {
						await onSubmitWithMessage(...args)?.catch(reject);
						return resolve(...args);
					},
					(e) => reject(e)
				)(e)
			),
		[handleSubmit, onSubmitWithMessage]
	);
	return (
		<FormProvider {...formMethods}>
			<FormDirtyContext.Provider value={{ isDirty, setIsDirty }}>
				<FormSubmitContext.Provider value={formSubmit}>
					{isSending ? <FormLoader /> : null}
					<form style={formStyle} onSubmit={(...args) => formSubmit(...args).then(submitCallback)}>
						<Flex
							flexDirection="column"
							height="100%"
							bg={bg}
							sx={{ position: 'relative', overflow: 'auto' }}
							pb={pb ?? 4}
							pt={pt ?? 4}
							px={px ?? [2, 4, 6]}
						>
							{stepper}
							{hideAlert ? null : stateMessage}
							{hideErrorAlert ? null : (
								<ControlledFormErrorAlert
									hasAnyErrors={isNotEmpty(errors)}
									errorTitleBuilder={errorTitleBuilder}
									errorMessageBuilder={errorMessageBuilder}
									message={errorMessage}
									mb={4}
								/>
							)}
							{children}
						</Flex>
						{!hideBottomBar && (
							<TextbookCreatorBottomBar sx={{ flex: '0 0 auto', px: [3] }}>
								{!hideBackStepButton && (
									<Flex sx={{ flex: '0 0 auto' }}>
										<Button
											label={backStepButtonLabel}
											fontSize={14}
											ml={12}
											variant="secondary"
											my={11}
											onClick={() => {
												if (!backStepButtonDisableSubmit) {
													formSubmit().then(() => backStepButtonAction());
												} else {
													backStepButtonAction();
												}
											}}
										/>
									</Flex>
								)}
								{!hideBackButton && (
									<Flex sx={{ flex: '0 0 auto' }}>
										<Button
											label={backButtonLabel}
											fontSize={14}
											ml={12}
											variant="secondary"
											my={11}
											onClick={(/* EVENT */) => navigate(-1)}
										/>
									</Flex>
								)}
								{hideSubmit ? null : (
									<Box width={1}>
										<Flex justifyContent="flex-end" alignItems="center" height="100%">
											{publishButton ? publishButton : null}
											<Button
												fontSize={14}
												label={formButtonLabel}
												type="submit"
												disabled={isSending}
												variant={isSending ? 'disabled' : 'primary'}
												sx={submitButtonStyle}
												data-cy="form-submit"
											/>
										</Flex>
									</Box>
								)}
							</TextbookCreatorBottomBar>
						)}
						<div id="textbook-creator-bottom-bar" />
					</form>
				</FormSubmitContext.Provider>
			</FormDirtyContext.Provider>
		</FormProvider>
	);
};

export const isFormStateDirty = (formState) => {
	const fields = formState?.dirtyFields ?? {};
	if (!fields) {
		return false;
	} else {
		return isNotEmpty(
			compose(
				dissoc('publishedAt'),
				dissoc('ratingCount'),
				dissoc('unusedPhrases'),
			)(deepFilter(fields, (value) => isNotNilOrEmpty(value)))
		);
	}
};

Form.propTypes = {
	backButtonLabel: PropTypes.string,
	backStepButtonAction: PropTypes.func,
	backStepButtonDisableSubmit: PropTypes.bool,
	backStepButtonLabel: PropTypes.string,
	bg: PropTypes.string,
	children: PropTypes.node,
	disableConfirmBeforeLeave: PropTypes.bool,
	errorMessage: PropTypes.string,
	errorMessageBuilder: PropTypes.func,
	errorTitleBuilder: PropTypes.func,
	formButtonLabel: PropTypes.string,
	formName: PropTypes.string.isRequired,
	hideAlert: PropTypes.bool,
	hideBackButton: PropTypes.bool,
	hideBackStepButton: PropTypes.bool,
	hideBottomBar: PropTypes.bool,
	hideErrorAlert: PropTypes.bool,
	hideSubmit: PropTypes.bool,
	hideSuccessAlert: PropTypes.bool,
	onSubmit: PropTypes.func,
	pb: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),
	pt: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),
	publishButton: PropTypes.node,
	px: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),
	stepper: PropTypes.node,
	submitButtonStyle: PropTypes.object,
	submitCallback: PropTypes.func,
	submitMessages: PropTypes.shape({
		errorTitle: PropTypes.string,
		errorMessage: PropTypes.string,
		successTitle: PropTypes.string,
		successMessage: PropTypes.string,
	}),
	tabIndex: PropTypes.number,
};
