import { filter, always, ifElse } from 'ramda';
import axios from 'axios';

import { uploadImageForModule } from '../images';
import { DECKS, TEXTBOOK_DECKS_SORT } from '../../constants/urls';
import { createPageableRequest, dataDataPath, dataP, isNotNilOrEmpty } from '../../utils';

export const getSingleDeck = (/* api cache key */ _, id) => axios.get(`${DECKS}/${id}`).then(({ data }) => data);

export const getAllDecks = (name, languageToSearchBy, language) =>
	axios
		.get(DECKS, {
			params: {
				limit: Number.MAX_SAFE_INTEGER,
				...(name ? { term: name } : {}),
				...(language ? { language } : {}),
				...(languageToSearchBy ? { baseLanguage: languageToSearchBy } : {}),
			},
		})
		.then(dataDataPath);

export const getAllTextbookDecks = (/* api cache key */ _, deckIds, { term, baseLanguage, limit }) =>
	ifElse(
		isNotNilOrEmpty,
		(deckIds) =>
			axios
				.get(DECKS, {
					params: {
						deckIds: deckIds,
						...(term ? { term } : {}),
						...(baseLanguage ? { baseLanguage } : {}),
						...(limit ? { limit } : {}),
						sortBy: 'ids',
					},
				})
				.then(({ data }) => ({
					data: data.data,
					totalCount: data.totalCount,
				})),
		always(Promise.resolve({ data: [] }))
	)(deckIds);

export const geAllDecksById = (_, ids) =>
	ifElse(
		isNotNilOrEmpty,
		(ids) => axios.get(DECKS, { params: { deck_ids: ids } }).then(dataDataPath),
		always(Promise.resolve([]))
	)(ids);

const preparePageableDecksSearchParams = ({ orderBy, orderDirection, search }) => {
	const { term, language, difficulty, after, sortDirection, sortBy } = search;
	const searchParams = {
		term,
		language: language?.value,
		difficulty: difficulty?.value ? difficulty?.label : null,
	};

	const sortParams = {
		sortBy: sortBy?.field ?? orderBy?.field,
		sortOrder: isNotNilOrEmpty(sortDirection) ? sortDirection : orderDirection,
	};
	const pageParams = { after };
	return { params: filter(isNotNilOrEmpty, { ...sortParams, ...searchParams, ...pageParams }) };
};
export const getPageableDecks = createPageableRequest(DECKS, 'deckId', preparePageableDecksSearchParams);

export const createDeck = (deck) => axios.post(DECKS, { deck }).then(dataP);

export const saveTextbookDecksOrder = (textbookId, order) => axios.put(TEXTBOOK_DECKS_SORT(textbookId), { order });

export const deleteDeck = (id) => axios.delete(`${DECKS}/${id}`).then(dataP);

export const deleteDecks = (deckIds) => axios.delete(DECKS, { params: { ids: deckIds } }).then(dataP);

export const bulkAddDecksToTextbooks = (data) => axios.post(`${DECKS}/textbooks`, data).then(dataP);

export const editDeck = (deck, id) => axios.put(`${DECKS}/${id}`, { deck }).then(dataP);

export const uploadDeckImage = uploadImageForModule(DECKS);

export const deleteCardInDeck = ({ deckId, cardId }) =>
	axios.delete(`${DECKS}/cards/${deckId}`, { params: { cardId } }).then(dataP);

export const getDeckPublished = (/* api cache key */ _, id) => axios.get(`${DECKS}/${id}/publish`).then(dataP);
export const publishDeck = (id) => axios.put(`${DECKS}/${id}/publish`).then(dataP);
export const unpublishDeck = (id) => axios.put(`${DECKS}/${id}/unpublish`).then(dataP);
export const prePublishDeck = (id) => axios.put(`${DECKS}/${id}/prepublish`).then(dataP);
export const unPrePublishDeck = (id) => axios.put(`${DECKS}/${id}/unprepublish`).then(dataP);
