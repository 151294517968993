import React from 'react';
import PropTypes from 'prop-types';
import { Flex, Button } from 'rebass';
import { MdClear } from 'react-icons/md';

export const ClearButton = ({ sx, ...other }) => (
	<Button
		p={0}
		sx={{
			borderRadius: '5px',
			borderColor: 'gray.400',
			minHeight: '24px',
			minWidth: '24px',
			cursor: 'pointer',
			'& > div': {
				fontSize: 2,
				color: 'gray.400',
			},
			...sx,
		}}
		bg="transparent"
		type="button"
		{...other}
	>
		<Flex justifyContent="center" alignItems="center">
			<MdClear />
		</Flex>
	</Button>
);

ClearButton.propTypes = {
	sx: PropTypes.object,
};
