import React from 'react';
import PropTypes from 'prop-types';
import { Box, Flex } from 'rebass';
import { PROFILE_BLOCK_HEIGHT } from '../../../theme';

export const TextbookCreatorBottomBar = ({ children, zIndex, sx = {} }) => (
	<>
		<Box
			sx={{
				borderTop: '1px solid',
				borderColor: 'gray.300',
				backgroundColor: 'menuBackground',
				zIndex: zIndex,
				height: PROFILE_BLOCK_HEIGHT,
				...sx,
			}}
		>
			<Flex justifyContent="space-between" alignItems="center" height="100%">
				{children}
			</Flex>
		</Box>
	</>
);

TextbookCreatorBottomBar.propTypes = {
	children: PropTypes.node,
	sx: PropTypes.object,
	zIndex: PropTypes.number,
};
