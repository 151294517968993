import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Box, Flex } from 'rebass';

import { ClearButton } from '../Button';
import { useBorderColor } from '../../theme';

export const EditorTreeIndicator = ({
	isLast,
	isLastClearable,
	hasLabel = false,
	isClearable = true,
	handleClick,
	'data-cy': dataCy,
}) => {
	const borderColor = useBorderColor();
	const borderStyles = `1px dotted ${borderColor}`;
	const sharedStyles = useMemo(
		() => ({
			height: '100%',
			sx: { borderBottom: borderStyles },
		}),
		[borderStyles]
	);
	const marginTop = hasLabel ? '30px' : '12px';
	return (
		<Box mx={2} width={32} sx={{ flexGrow: 1 }}>
			<Box
				height={isLast || isLastClearable ? 'calc(50% + 6px)' : '100%'}
				sx={{
					borderLeft: borderStyles,
				}}
			>
				{isLast ? (
					<Box {...sharedStyles} />
				) : isLastClearable ? (
					<Flex alignItems="flex-end" {...sharedStyles}>
						<ClearButton
							data-cy={dataCy}
							mt={marginTop}
							bg="menuBackground"
							height={24}
							sx={{ transform: 'translate(calc(50% - 2px), 12px)' }}
							onClick={handleClick}
							tabIndex="-1"
						/>
					</Flex>
				) : (
					<Flex height="100%" alignItems="center">
						<Box mt={marginTop} sx={{ borderBottom: borderStyles, minWidth: isClearable ? '10px' : '100%' }} />
						{isClearable && <ClearButton mt={marginTop} onClick={handleClick} tabIndex="-1" />}
					</Flex>
				)}
			</Box>
		</Box>
	);
};

EditorTreeIndicator.propTypes = {
	'data-cy': PropTypes.string,
	elemHeight: PropTypes.number,
	handleClick: PropTypes.func,
	hasLabel: PropTypes.bool,
	isClearable: PropTypes.bool,
	isLast: PropTypes.bool,
	isLastClearable: PropTypes.bool,
};
