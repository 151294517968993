import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'ramda-extension';
import { Flex, Text, Box } from 'rebass';

import { useIsCurrent } from './hooks';
import { SvgIcon } from '../Icon';
import { privilegesAction } from '../../constants';
import { useHasForModuleAction } from '../../features/authorization/hooks';
import { useNavigationWithState } from '../../features/locationState/locationContext';

export const MenuItem = ({
	disabled,
	module,
	label,
	icon,
	toUrl,
	isSubmenu = false,
	hideLabel = false,
	callback = noop,
	isDefault = false,
}) => {
	const isVisible = useHasForModuleAction(module, privilegesAction.VIEW);
	const isCurrent = useIsCurrent(toUrl, isDefault);
	const navigate = useNavigationWithState();
	const textColor = disabled ? 'gray.400' : isCurrent ? 'primary' : 'textPrimary';
	const iconColor = disabled ? 'gray.300' : isCurrent ? 'primary' : 'gray.500';
	const hoverColor = disabled ? 'gray.400' : isSubmenu? '' : 'primary';
	const cursor = disabled || isSubmenu ? 'default' : 'pointer';

	if (!isVisible) {
		return null;
	}

	const menuItemContent = (
		<Flex
			justifyContent="flex-start"
			height={hideLabel ? 64 : 50}
			sx={{
				cursor,
				'&:hover .Label': {
					color: hoverColor,
				},
				'&:hover svg': {
					fill: hoverColor,
					stroke: hoverColor,
				},
			}}
		>
			<Box width="4px" sx={{ borderRadius: '0 10px 10px 0', bg: isCurrent ? 'primary' : 'transparent' }} />
			<Flex
				alignItems="center"
				alignSelf="center"
				pl={hideLabel ? 0 : '10px'}
				pb={10}
				pt={15}
				width="100%"
				flexDirection={hideLabel ? 'column' : undefined}
			>
				<Flex alignItems="center" justifyContent="center" width={54}>
					<SvgIcon color={iconColor} size={20}>
						{icon}
					</SvgIcon>
				</Flex>
				<Text
					className="Label"
					fontSize={hideLabel ? 10 : 14}
					width={hideLabel ? undefined : '65%'}
					textAlign={hideLabel ? 'center' : undefined}
					fontWeight={500}
					color={textColor}
				>
					{label}
				</Text>
			</Flex>
		</Flex>
	);
	return disabled || isSubmenu ? (
		menuItemContent
	) : (
		<Text
			data-cy={module}
			onClick={() => {
				if (toUrl) {
					navigate(toUrl, {});
				}
				callback();
			}}
		>
			{menuItemContent}
		</Text>
	);
};

MenuItem.propTypes = {
	callback: PropTypes.func,
	disabled: PropTypes.bool,
	hideLabel: PropTypes.bool,
	icon: PropTypes.element,
	isDefault: PropTypes.bool,
	isSubmenu: PropTypes.bool,
	label: PropTypes.string.isRequired,
	module: PropTypes.string,
	toUrl: PropTypes.string,
};
