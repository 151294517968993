import { keyMirror } from 'ramda-extension';

export const rafiModules = keyMirror({
	WORDS: null,
	DICTIONARY: null,
	CARDS: null,
	DECKS: null,
	TEXTBOOKS: null,
	MULTI_FORM: null,
	USERS: null,
	APP_USERS: null,
	FEATURED_TEXTBOOK: null,
	PUBLISHERS: null,
	WORD_TYPES: null,
	DIFFICULTIES: null,
	LANGUAGES: null,
	ROLES: null,
	TAGS: null,
});

export const rafiRoleModules = keyMirror({
	RATING: null,
	PUBLISHING: null,
});
