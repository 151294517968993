import React from 'react';
import PropTypes from 'prop-types';
import { Text } from 'rebass';

import { useHasSectionErrors } from './hooks';

export const SectionAwareTabLabel = ({ label, sectionKey }) => {
	const hasError = useHasSectionErrors(sectionKey ?? label);
	return (
		<div>
			<Text fontSize={1} fontWeight={500} color={hasError ? 'red.500' : undefined}>
				{label}
			</Text>
		</div>
	);
};

SectionAwareTabLabel.propTypes = {
	label: PropTypes.string.isRequired,
	sectionKey: PropTypes.string,
};
