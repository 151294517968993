import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty, isNil } from 'ramda';
import { isNotNil } from 'ramda-extension';
import { Controller, useFormContext } from 'react-hook-form';
import { MdErrorOutline } from 'react-icons/md';
import get from 'lodash.get';

import { Icon } from '../../components/Icon';
import { FormLabel, TagsInput } from '../../components/Forms';
import { FormTooltip } from '../../components/Forms/FormTooltip';
import { useSetErrorForSectionEffect } from '../../features/formSectionError';

export const ControlledField = ({
	label,
	component: Component = TagsInput,
	validationMessage,
	name,
	rules,
	labelProps,
	defaultValue,
	errorSx = {},
	errorOffset,
	hideError,
	onChange: onChangeProp,
	onBlur: onBlurProp,
	...other
}) => {
	const { control, errors } = useFormContext();
	const fieldError = get(errors, name);
	const hasErrors = isNotNil(fieldError);
	// eslint-disable-next-line
	const star = rules?.required ? "'*'" : "''";
	const isLabelHidden = !!(isEmpty(label) || isNil(label));
	useSetErrorForSectionEffect(hasErrors, name);
	return (
		<>
			{isLabelHidden ? null : (
				<FormLabel
					label={label}
					htmlFor={name}
					{...labelProps}
					sx={{
						'&:after': {
							color: 'red.600',
							content: `${star}`,
						},
					}}
				/>
			)}
			<Controller
				control={control}
				name={name}
				rules={rules}
				defaultValue={defaultValue}
				render={(props) => {
					const onChange = (e) => {
						if (onChangeProp) {
							onChangeProp(e);
						}
						props.onChange(e);
					};
					const onBlur = (e) => {
						if (onBlurProp) {
							onBlurProp(e);
						}
						props.onBlur(e);
					};
					return (
						<div>
							<Component
								hasErrors={hasErrors}
								name={name}
								autocomplete="off"
								variant={hasErrors ? 'error' : undefined}
								errorMessage={!hideError && fieldError?.message}
								{...other}
								{...props}
								onChange={onChange}
								onBlur={onBlur}
							/>
							<div style={{ float: 'right', zIndex: '999', height: 0 }} data-cy={`${name}.error`}>
								<FormTooltip
									message={fieldError?.message || 'Pole není správně vyplněno'}
									offset={errorOffset}
									component={
										hasErrors ? (
											<Icon
												sx={{
													position: 'relative',
													color: 'red.600',
													float: 'right',
													bottom: '30px',
													right: '12px',
													...errorSx,
												}}
												icon={<MdErrorOutline />}
											/>
										) : null
									}
								/>
							</div>
						</div>
					);
				}}
			/>
		</>
	);
};

ControlledField.propTypes = {
	component: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
	defaultValue: PropTypes.any,
	errorOffset: PropTypes.array,
	errorSx: PropTypes.object,
	hideError: PropTypes.bool,
	label: PropTypes.string,
	labelProps: PropTypes.object,
	name: PropTypes.string,
	onBlur: PropTypes.func,
	onChange: PropTypes.func,
	rules: PropTypes.object,
	validationMessage: PropTypes.string,
};
