import { useCallback } from 'react';

export const useScrollToTop = () => useCallback(() => window.scrollTo(0, 0), []);

export const useScrollTo = (id) =>
	useCallback(() => {
		const element = document.getElementById(id);
		// eslint-disable-next-line no-unused-expressions
		element?.scrollIntoView?.(true);
	}, [id]);
