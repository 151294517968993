import React, { Suspense } from 'react';
import { Box, Flex } from 'rebass';
import { LocationProvider } from '@reach/router';

import './config';

import { GlobalStyles } from './GlobalStyles';
import { PreloaderStyles } from './PreloaderStyles';
import { Routes, useIsAuthenticated } from '../../routes';
import { FullscreenLoader } from '../../components/Loaders';
import { MenuWrapper } from '../../components/Menu/MenuWrapper';

import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light.css';
import 'cropperjs/dist/cropper.css';
import 'react-quill/dist/quill.snow.css';
import 'react-tabs/style/react-tabs.css';
import '@brainhubeu/react-carousel/lib/style.css';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import 'react-contexify/dist/ReactContexify.min.css';

const App = () => {
	const isAuthenticated = useIsAuthenticated();
	return (
		<LocationProvider>
			<Box>
				<GlobalStyles />
				<PreloaderStyles />
				<Flex>
					{isAuthenticated && <MenuWrapper />}
					<Box width="100%">
						<Suspense fallback={<FullscreenLoader />}>
							<Routes />
						</Suspense>
					</Box>
				</Flex>
			</Box>
		</LocationProvider>
	);
};

export default App;
