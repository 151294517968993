export const SEARCH_URI = '/search';
export const SAVE_ALL_WORDS = '/words';
export const CARDS = '/cards';
export const TEXTBOOKS = '/textbooks';
export const DECKS = '/decks';
export const TEXTBOOK_DECKS_SORT = (textbookId) => `${TEXTBOOKS}/${textbookId}${DECKS}/sort`;
export const FEATURED_TEXTBOOKS = '/featured-textbooks';
export const CODELIST = '/codelist';
export const USERS = '/users';
export const APP_USERS = '/app-users';
export const GROUPS = '/groups';
export const LOGIN = '/login';
export const REGISTER = '/register';
export const LOGIN_WITH_GOOGLE = '/login/google';
export const LOGIN_WITH_APPLE = '/login/apple';
export const ROLES = '/roles';
export const LANGUAGES = '/languages';
export const DIFFICULTIES = '/difficulties';
export const PUBLISHERS = '/publishers';
export const PUBLISHER_AUTHORS = '/publisher-authors';
export const PRIVILEGES = '/privileges';
export const TAGS = '/tags';
export const PHRASES_SETS = '/phrase-sets';
