import { useMemo } from 'react';
import { identity } from 'ramda';
import { useQuery } from 'react-query';

import { emptyArray, fiveMinutesMs, LANGUAGES_CODELIST_NAME } from '../../constants';
import { getCodelist, codelistCacheKeys, getRawCodelist } from '../../api/codelists';
import { findBaseLanguageId } from './utils';

export const useCodelist = (codelistName, optionsTransform = identity, dataTransform = identity) => {
	const { data: rawData, isSuccess, isError, isLoading, refetch } = useQuery(
		[codelistCacheKeys.SINGLE_CODELIST, codelistName],
		getCodelist,
		{
			staleTime: fiveMinutesMs,
			retry: 2
		});
	const data = useMemo(() => {
		if (isSuccess) {
			return dataTransform(rawData.map(optionsTransform));
		}
		return emptyArray;
	}, [isSuccess, dataTransform, optionsTransform, rawData]);
	return { data, rawData, isError, isSuccess, isLoading, refetch };
};

export const useRawCodelist = (codelistName, optionsTransform = identity, dataTransform = identity) => {
	const { data: rawData, isSuccess, isError, isLoading, refetch } = useQuery(
		[codelistCacheKeys.SINGLE_CODELIST_RAW, codelistName],
		getRawCodelist,
		{
			staleTime: fiveMinutesMs,
		}
	);
	const data = useMemo(() => {
		if (isSuccess) {
			return dataTransform(rawData.map(optionsTransform));
		}
		return emptyArray;
	}, [isSuccess, dataTransform, optionsTransform, rawData]);
	return { data, rawData, isError, isSuccess, isLoading, refetch };
};

export const useBaseLanguageId = () => {
	const { data: languages, isSuccess, isError, isLoading } = useRawCodelist(LANGUAGES_CODELIST_NAME);
	const id = useMemo(() => {
		if (isSuccess) {
			return findBaseLanguageId(languages);
		}
		return emptyArray;
	}, [isSuccess, languages]);
	return { id, isSuccess, isLoading, isError };
};
