import React from 'react';
import { Global } from '@emotion/core';
import Bowser from 'bowser';

import {
	useGrayShade,
	useOrangeShade,
	useBorderColor,
	usePrimaryColor,
	useMenuBackground,
	useTextPrimaryColor,
	useRedShade,
	useDisabledColor,
} from '../../theme';
import { REACT_SELECT_CLASSNAME_PREFIX } from '../../components/Forms/ReactSelect';

export const GlobalStyles = () => {
	const primaryColor = usePrimaryColor();
	const menuBackgroundColor = useMenuBackground();
	const borderColor = useBorderColor();
	const primaryTextColor = useTextPrimaryColor();
	const lightOrange = useOrangeShade(100);
	const lightBackgroundColor = useGrayShade(100);
	const muchDarkerOrangeBackgroundColor = useOrangeShade(300);
	const lightGray = useGrayShade(400);
	const gray700 = useGrayShade(700);
	const disabledColor = useDisabledColor();
	const darkerBackgroundColor = useGrayShade(200);
	const focusBorderColor = useGrayShade(600);
	const darkerRed = useRedShade(600);
	const browser = Bowser.getParser(window.navigator.userAgent);
	const isAppleOs = browser.getOSName().toLowerCase() === 'macos' || browser.getOSName().toLowerCase() === 'ios';
	const specificStyleByOs = !isAppleOs
		? {
				'::-webkit-scrollbar': {
					background: 'transparent',
					height: '8px',
					width: '8px',
				},
				'::-webkit-scrollbar-thumb': {
					border: 'none',
					'-webkit-box-shadow': 'none',
					boxShadow: 'none',
					background: '#ddd',
					minHeight: '40px',
				},
				':hover': {
					'&::-webkit-scrollbar-thumb': {
						background: '#bdbdbd',
					},
				},
				// eslint-disable-next-line no-mixed-spaces-and-tabs
		  }
		: {};
	return (
		<Global
			styles={() => ({
				body: {
					minWidth: '1280px',
				},
				a: {
					textDecoration: 'none',
				},
				svg: {
					display: 'block',
				},
				'.show-for-sr': {
					position: 'absolute !important',
					width: '1px !important',
					height: '1px !important',
					padding: '0 !important',
					overflow: 'hidden !important',
					clip: 'rect(0, 0, 0, 0) !important',
					'white-space': 'nowrap !important',
					border: '0 !important',
				},
				// react contexify
				'.react-contexify__item__content': {
					fontSize: '14px',
					'&:hover': {
						backgroundColor: `${muchDarkerOrangeBackgroundColor} !important`,
					},
				},
				// react tour
				'.helper': {
					'--reactour-accent': primaryColor,
					lineHeight: 1.3,
					color: primaryTextColor,
				},
				// React-modal
				'.ReactModal__Overlay--after-open': {
					zIndex: 999,
				},
				// Tippy
				'.tippy-popper .tippy-tooltip.dark-theme': {
					padding: '6px 8px',
					backgroundColor: 'rgba(0, 0, 0, 0.7) !important',
				},
				'.tippy-tooltip': {
					'& [x-circle]': {
						backgroundColor: 'transparent !important',
					},
				},
				'.tippy-popper[aria-hidden="true"]': {
					display: 'none',
				},
				// Cropper,
				'.cropper-view-box': {
					outline: '1px solid white !important',
					outlineColor: 'white !important',
				},
				'.cropper-face': {
					backgroundColor: 'transparent !important',
				},
				'.cropper-line': {
					backgroundColor: 'white !important',
				},
				'.cropper-point': {
					backgroundColor: 'white !important',
					borderRadius: '50%',
				},
				// quill,
				'.ql-disabled': {
					background: `${disabledColor}`,
				},
				'.ql-toolbar, .ql-container': {
					border: 'none !important',
				},
				'.ql-editor.ql-blank::before': {
					color: `${lightGray} !important`,
					fontSize: '14px',
					fontFamily: 'Inter',
					fontStyle: 'normal !important',
				},
				'.ql-toolbar': {
					borderBottom: `1px solid ${borderColor} !important`,
					backgroundColor: `${lightBackgroundColor} !important`,
				},
				'.ql-snow.ql-toolbar button:hover .ql-stroke, .ql-snow .ql-toolbar button:hover .ql-stroke, .ql-snow.ql-toolbar button:focus .ql-stroke, .ql-snow .ql-toolbar button:focus .ql-stroke, .ql-snow.ql-toolbar button.ql-active .ql-stroke, .ql-snow .ql-toolbar button.ql-active .ql-stroke, .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke, .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke, .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke, .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke, .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke, .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke, .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke, .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke, .ql-snow.ql-toolbar button:hover .ql-stroke-miter, .ql-snow .ql-toolbar button:hover .ql-stroke-miter, .ql-snow.ql-toolbar button:focus .ql-stroke-miter, .ql-snow .ql-toolbar button:focus .ql-stroke-miter, .ql-snow.ql-toolbar button.ql-active .ql-stroke-miter, .ql-snow .ql-toolbar button.ql-active .ql-stroke-miter, .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-miter, .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke-miter, .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter, .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter, .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-miter, .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke-miter, .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter, .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter': {
					stroke: `${primaryColor} !important`,
					color: `${primaryColor} !important`,
				},
				// react-rating-stars-component
				'.Rafi-starRating': {
					outlineColor: focusBorderColor,
				},
				'.Component-horizontalScrollContainer-1': {
					'& ::-webkit-scrollbar': {
						background: 'transparent',
						height: '8px',
						width: '8px',
					},
					'& ::-webkit-scrollbar-thumb': {
						border: 'none',
						'-webkit-box-shadow': 'none',
						boxShadow: 'none',
						background: '#ddd',
						minHeight: '40px',
					},
				},
				// material-table
				'.MuiLinearProgress-barColorPrimary': {
					backgroundColor: `${primaryColor} !important`,
				},
				'.MuiLinearProgress-colorPrimary': {
					backgroundColor: `${lightOrange} !important`,
				},
				'.MuiIconButton-root:hover': { backgroundColor: 'transparent !important' },
				'.Table--no_selection': {
					'.MuiTableRow-root': {
						'& .MuiTableCell-body': {
							'&:first-child': {
								paddingRight: '16px !important',
							},
							'&:nth-child(2)': {
								paddingLeft: '16px !important',
							},
						},
					},
				},
				'.MuiTypography-root': {
					color: `${primaryTextColor} !important`,
					fontFamily: 'Inter !important',
					fontStyle: 'normal !important',
				},
				'.MuiTypography-h6': {
					color: `${darkerRed} !important`,
					fontFamily: 'Inter !important',
					fontStyle: 'normal !important',
					fontSize: '18px !important',
				},
				'.MuiCircularProgress-colorPrimary': {
					color: `${primaryColor} !important`,
				},
				'.MTableHeader-header-6': {
					color: `${primaryTextColor} !important`,
				},
				'.MuiTableCell-head': {
					border: 'none',
					boxShadow: `4px 2px 4px ${borderColor}`,
					color: 'gray.700',
					fontSize: '14px',
					fontFamily: 'Inter !important',
					fontStyle: 'normal !important',
					fontWeight: '700 !important',
					'& *': {
						fontWeight: '700 !important',
						fontFamily: 'Inter !important',
					},
					'& .MuiTableSortLabel-active': {
						color: `${primaryColor} !important`,
					},
				},
				'.MuiTextField-root': {
					width: '100%',
				},
				'.MuiTableRow-root': {
					color: `${primaryTextColor} !important`,
					'& .MuiTableCell-body': {
						'&:first-child': {
							paddingRight: '0 !important',
						},
						'&:nth-child(2)': {
							paddingLeft: '0 !important',
						},
					},
				},
				'.MuiTableHead-root': {
					'& .MuiTableRow-root': {
						height: '50px',
					},
					'& .MuiTableCell-root': {
						padding: '4px 16px',
					},
				},
				'.MuiTableBody-root': {
					'& .MuiTableRow-root': {
						height: '40px',
					},
					'& .MuiTableCell-root': {
						'& *': {
							maxHeight: '40px',
						},
						lineHeight: '0',
						padding: '0 16px',
					},
				},
				'.MuiTableCell-body': {
					borderColor: `${borderColor} !important`,
					backgroundColor: menuBackgroundColor,
					fontSize: '14px !important',
				},
				'.MuiTable-root': {
					color: 'gray.600',
				},
				'.MuiIconButton-label': {
					'& > svg': {
						color: `${lightGray} !important`,
					},
				},
				'.MuiInput-underline:after': {
					borderColor: `${primaryColor} !important`,
				},
				'.MuiCheckbox-colorSecondary.Mui-checked': {
					color: `${primaryColor} !important`,
				},
				'.MuiCheckbox-root': {
					color: `${borderColor} !important`,
				},
				'.MuiTableSortLabel-active': {
					'& > div': {
						color: 'primary',
					},
					'& > svg': {
						color: `${primaryColor} !important`,
					},
				},
				// react-select
				[`.${REACT_SELECT_CLASSNAME_PREFIX}__control`]: {
					borderRadius: '3px',
					height: '40px',
					outline: 'none',
					fontSize: '14px',
					borderColor: `${borderColor} !important`,
					boxShadow: 'none  !important',
					'&:hover,&:focus,&:focus-within,&:active': {
						outline: 'none',
						boxShadow: 'none',
					},
					'&:focus,&:focus-within,&:active': {
						borderColor: `${primaryTextColor} !important`,
					},
					[`& .${REACT_SELECT_CLASSNAME_PREFIX}__input`]: {
						height: '100%',
						'& input': {
							height: '100%',
						},
					},
					'&--is-disabled': {
						[`& .${REACT_SELECT_CLASSNAME_PREFIX}__multi-value__remove`]: {
							display: 'none',
						},
					},
					[`& .${REACT_SELECT_CLASSNAME_PREFIX}__placeholder`]: {
						color: lightGray,
					},
					[`& .${REACT_SELECT_CLASSNAME_PREFIX}__indicator-separator`]: {
						display: 'none',
					},
					[`& .${REACT_SELECT_CLASSNAME_PREFIX}__dropdown-indicator`]: {
						'& svg path': {
							color: `${lightGray} !important`,
						},
					},
					[`& .${REACT_SELECT_CLASSNAME_PREFIX}__clear-indicator`]: {
						'& svg path': {
							color: `${lightGray} !important`,
						},
					},
					[`& .${REACT_SELECT_CLASSNAME_PREFIX}__multi-value`]: {
						backgroundColor: darkerBackgroundColor,
						padding: '2px 6px',
						border: 0,
						[`& .${REACT_SELECT_CLASSNAME_PREFIX}__multi-value__label`]: {
							fontSize: '14px',
							fontWeight: 500,
						},
						[`& .${REACT_SELECT_CLASSNAME_PREFIX}__multi-value__remove`]: {
							cursor: 'pointer',
							'&:hover': {
								backgroundColor: 'transparent !important',
								'& svg': {
									color: `${primaryTextColor} !important`,
								},
							},
							'& svg': {
								color: `${lightGray} !important`,
							},
						},
					},
				},
				[`.${REACT_SELECT_CLASSNAME_PREFIX}__menu`]: {
					zIndex: '12 !important',
					[`& .${REACT_SELECT_CLASSNAME_PREFIX}__menu-list`]: {
						[`& .${REACT_SELECT_CLASSNAME_PREFIX}__option--is-selected`]: {
							backgroundColor: '#fff !important',
							color: `${primaryTextColor} !important`,
							fontWeight: 500,
						},
						[`& .${REACT_SELECT_CLASSNAME_PREFIX}__option--is-focused`]: {
							backgroundColor: `${lightOrange} !important`,
							color: `${primaryTextColor} !important`,
						},
						[`& .${REACT_SELECT_CLASSNAME_PREFIX}__option`]: {
							color: `${gray700}`,
						},
					},
				},
				'.fast-option-focused': {
					backgroundColor: 'rgba(221,107,32,0.7) !important',
				},
				'.fast-option-selected': {
					backgroundColor: `${primaryColor} !important`,
				},
				'input:-webkit-autofill': {
					WebkitBoxShadow: '0 0 0 1000px white inset !important',
				},
				...specificStyleByOs,
			})}
		/>
	);
};
