import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { components } from 'react-select';
import { Box, Flex, Text } from 'rebass';

import { REACT_SELECT_CLASSNAME_PREFIX } from './constants';
import { BASE_LANGUAGE } from '../../../constants';
import { useBorderColor } from '../../../theme';

export const LocalizedSelectContainer = forwardRef(({ children, languageCode = BASE_LANGUAGE, ...props }, ref) => (
	<Flex width="100%">
		<Box
			bg="gray.100"
			sx={{
				border: '1px solid',
				minWidth: '48px',
				borderRadius: '3px 0 0 3px',
				borderColor: useBorderColor(),
				borderRight: 0,
			}}
			height="40x"
		>
			<Flex height="100%" alignItems="center" justifyContent="center">
				<Text color="gray.700" fontSize={1} sx={{ textTransform: 'uppercase' }}>
					{languageCode}
				</Text>
			</Flex>
		</Box>
		<Box
			width="100%"
			sx={{
				[`& .${REACT_SELECT_CLASSNAME_PREFIX}__control`]: {
					borderRadius: '0 3px 3px 0 !important',
				},
			}}
		>
			<components.SelectContainer ref={ref} {...props}>
				{children}
			</components.SelectContainer>
		</Box>
	</Flex>
));

LocalizedSelectContainer.propTypes = {
	children: PropTypes.any,
	languageCode: PropTypes.string,
};
