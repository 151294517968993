import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { not } from 'ramda';
import { Box, Flex, Text } from 'rebass';
import { MdExitToApp, MdExpandLess, MdExpandMore, MdPersonOutline } from 'react-icons/md';

import { useTranslation } from 'react-i18next';
import { Icon } from '../Icon';
import { useLogout, useUserFullName } from '../../routes';
import { MenuItem } from './MenuItem';

export const UserItem = ({ hideLabel }) => {
	const userFullName = useUserFullName();
	const [showUserMenu, setShowUserMenu] = useState(false);
	const logout = useLogout();
	const { t } = useTranslation('navigation');

	return (
		<Flex
			py={13}
			alignItems="center"
			justifyContent="space-between"
			sx={{ borderBottom: '1px solid', borderColor: 'gray.300' }}
		>
			<Flex flexDirection="column">
				<Box px={24}>
					{hideLabel ? (
						<Icon
							icon={showUserMenu ? <MdExpandLess /> : <MdExpandMore />}
							fontSize={30}
							color="gray.400"
							height={32}
							width={32}
							onClick={() => setShowUserMenu(not)}
						/>
					) : (
						<>
							<Flex alignItems="center">
								<Icon
									icon={showUserMenu ? <MdExpandLess /> : <MdExpandMore />}
									fontSize={30}
									color="gray.400"
									height={32}
									width={32}
									onClick={() => setShowUserMenu(not)}
								/>
								<Flex ml={16} flexDirection="column">
									<Text color="gray.600" fontSize={11}>
										{t('navigation.user.label')}
									</Text>
									<Text color="orange.600" fontSize={14} fontWeight={500}>
										{userFullName}
									</Text>
								</Flex>
							</Flex>
						</>
					)}
				</Box>
				{showUserMenu ? (
					<Flex flexDirection="column" mt={2}>
						<MenuItem
							label={t('navigation.user.profile')}
							icon={<MdPersonOutline />}
							toUrl="/profile"
							hideLabel={hideLabel}
						/>
						<MenuItem
							label={t('navigation.user.logout')}
							icon={<MdExitToApp />}
							callback={logout}
							hideLabel={hideLabel}
						/>
					</Flex>
				) : null}
			</Flex>
		</Flex>
	);
};

UserItem.propTypes = {
	hideLabel: PropTypes.bool,
};
