import React from 'react';
import PropTypes from 'prop-types';
import { take } from 'ramda';
import { gteThanLength } from 'ramda-extension';
import { Box, Flex, Text } from 'rebass';
import { MdClear } from 'react-icons/md';

import { textFieldFocusMode } from './constants';
import { TextField } from './TextField';
import { Icon } from '../Icon';
import { Tooltip } from '../Tooltip';
import { useBorderColor } from '../../theme';
/* eslint-disable no-mixed-spaces-and-tabs */
export const ClearableLanguageTextField = ({
	languageCode = '',
	onClear,
	hideClearIcon = true,
	variant = 'withLanguage',
	'data-cy': dataTut,
	name,
	...other
}) => {
	const borderColor = useBorderColor();
	const wrapperStyle =
		variant === 'noBorder'
			? {
					border: '1px solid',
					minWidth: '48px',
					borderRadius: '3px',
					borderColor: borderColor,
			  }
			: {
					border: '1px solid',
					minWidth: '48px',
					borderRadius: '3px 0 0 3px',
					borderColor: borderColor,
			  };
	return (
		<Flex data-cy={dataTut ?? name}>
			<Box bg="gray.100" sx={wrapperStyle} height="40x">
				<Flex height="100%" alignItems="center" justifyContent="center">
					{gteThanLength(3, languageCode) ? (
						<Text color="gray.700" fontSize={1} sx={{ textTransform: 'uppercase' }}>
							{languageCode}
						</Text>
					) : (
						<Tooltip fontSize={1} label={languageCode}>
							<Text color="gray.700" fontSize={1} sx={{ textTransform: 'uppercase' }}>
								{take(3, languageCode)}
							</Text>
						</Tooltip>
					)}
				</Flex>
			</Box>
			<TextField
				focusMode={textFieldFocusMode.INPUT}
				variant={variant}
				suffixElement={hideClearIcon ? null : <Icon px={2} color="primary" icon={<MdClear onClick={onClear} />} />}
				languageCode={languageCode}
				name={name}
				{...other}
			/>
		</Flex>
	);
};

ClearableLanguageTextField.propTypes = {
	'data-cy': PropTypes.string,
	hideClearIcon: PropTypes.bool,
	languageCode: PropTypes.string,
	name: PropTypes.string,
	onClear: PropTypes.func,
	variant: PropTypes.string,
};
