import React, { memo, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { defaultToTrue } from 'ramda-extension';
import { MenuProvider } from 'react-contexify';

import { replacePunctuation } from '../utils/wordSanitization';

export const DictionaryWord = memo(({ id, behaviourSubjectRef, children, languageCode, isLast }) => {
	const [style, setStyle] = useState({});
	useEffect(() => {
		const subject = behaviourSubjectRef.current;
		subject.subscribe((spellingData) => {
			const isCorrect = defaultToTrue(spellingData[replacePunctuation(children)]);
			if (isCorrect) {
				setStyle({});
			} else {
				setStyle({
					textDecoration: 'underline',
					textDecorationColor: 'red',
				});
			}
		});
	}, [behaviourSubjectRef, children]);
	const data = useMemo(() => ({ word: children, languageCode }), [children, languageCode]);
	return (
		<>
			<MenuProvider style={style} id={id} data={data} component="span">
				{children}
			</MenuProvider>
			{isLast ? null : <span>&nbsp;</span>}
		</>
	);
});

DictionaryWord.propTypes = {
	behaviourSubjectRef: PropTypes.any,
	children: PropTypes.any,
	id: PropTypes.string,
	isLast: PropTypes.bool,
	languageCode: PropTypes.string,
};
