import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import { composeWithDevTools } from 'redux-devtools-extension';

import rootReducer from './rootReducer';
import { getIsDev } from '../utils';

export const configureStore = (preloadedState) => {
	let middlewares = [thunk];
	if (getIsDev()) {
		middlewares = [...middlewares, logger];
	}
	return createStore(rootReducer, preloadedState, composeWithDevTools(applyMiddleware(...middlewares)));
};
