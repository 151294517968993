import React from 'react';
import PropTypes from 'prop-types';
import { equals, find } from 'ramda';
import { isNotNil } from 'ramda-extension';
import { Box, Flex } from 'rebass';

import { EditorTreeIndicator } from '../../../components/Editor';
import { InvisibleSimpleUncontrolledField } from '../InvisibleSimpleUncontrolledField';
import { ControlledField } from '../ControlledField';
import { isNotNilOrEmpty, validateWysiwyg } from '../../../utils';
import { DictionaryInput } from '../../../features/dictionary/components/DictionaryControlledInput';
import { TextField } from '../../../components/Forms';
import { LANGUAGE_LABEL_SEPARATOR } from '../../../constants';

export const TranslationSubField = ({
	id,
	code,
	value,
	label,
	index,
	languages,
	fieldArrayKey,
	secondaryFieldName,
	secondaryFieldComponent = TextField,
	secondaryLabel,
	translationLabel,
	hidden,
	fieldName,
	labelProps,
	disabled,
	removeLanguage,
	hasAnyLanguageCodes,
	onAnyBlur,
	onAnyFocus,
	fieldComponent: FieldComponent,
	isLast,
	otherTranslation0,
	...other
}) => {
	const language = isNotNilOrEmpty(languages)
		? find(({ value, ...other }) => equals(value.toString(), code?.toString()), languages)
		: [];
	const fieldRowPath = `${fieldArrayKey}[${index}]`;
	const hasLabel = translationLabel || secondaryLabel;
	return (
		<Box sx={{ display: hidden ? 'none' : undefined }}>
			<Flex key={id} alignItems="stretch" data-cy={`${fieldName}_${index + 1}_wrapper`}>
				<Flex width={secondaryFieldName ? 0.5 : 1}>
					<EditorTreeIndicator
						key={id}
						isClearable={!disabled}
						data-cy={`${fieldName}_${index + 1}.delete`}
						handleClick={(/* EVENT */) => removeLanguage(index)}
						isLastClearable={(!hasAnyLanguageCodes && isLast) || disabled}
						hasLabel={hasLabel}
					/>
					<Box mt="12px" width={1}>
						<InvisibleSimpleUncontrolledField name={`${fieldRowPath}.code`} defaultValue={code} />
						<InvisibleSimpleUncontrolledField name={`${fieldRowPath}.label`} defaultValue={label} />
						<ControlledField
							rules={{ required: hidden ? undefined : isNotNil(code), validate: validateWysiwyg }}
							name={`${fieldRowPath}.value`}
							defaultValue={value}
							languageCode={label}
							component={FieldComponent}
							label={translationLabel}
							labelProps={{ my: 0 }}
							disabled={disabled}
							readOnly={disabled}
							data-cy={`${fieldName}_${index + 1}`}
							onFocus={onAnyFocus}
							onBlur={onAnyBlur}
							hideClearIcon
							autoFocus
							inputElement={DictionaryInput}
						/>
					</Box>
				</Flex>
				{secondaryFieldName && (
					<Box width={0.5} mt="12px" ml={25}>
						<Flex>
							<Box width={1}>
								<ControlledField
									name={`${fieldRowPath}.${secondaryFieldName}`}
									component={secondaryFieldComponent}
									languageId={code}
									languageLabel={label}
									label={secondaryLabel}
									labelProps={{ my: 0 }}
									disabled={disabled}
									readOnly={disabled}
									inputElement={DictionaryInput}
									data-cy={`${secondaryFieldName}_0`}
								/>
							</Box>
						</Flex>
					</Box>
				)}
			</Flex>
			{language?.translationLabels &&
				language?.translationLabels.split(LANGUAGE_LABEL_SEPARATOR).map((translationLabel, index) => {
					const newIndex = index + 2;
					return (
						<Flex
							key={`${fieldRowPath}.otherTranslation${newIndex}`}
							alignItems="stretch"
							data-cy={`${fieldName}_otherTranslation_${newIndex}_wrapper`}
						>
							<Flex width={secondaryFieldName ? 'calc(50% - 12px)' : 1}>
								<EditorTreeIndicator isClearable={false} isLastClearable={false} />
								<Box mt="12px" width={1}>
									<ControlledField
										rules={{ validate: hidden ? undefined : validateWysiwyg }}
										name={`${fieldRowPath}.otherTranslation${newIndex}`}
										languageCode={translationLabel}
										actualLanguageCode={label}
										defaultValue={otherTranslation0}
										component={FieldComponent}
										labelProps={{ my: 0 }}
										disabled={disabled}
										readOnly={disabled}
										data-cy={`${fieldName}_otherTranslation_${newIndex}`}
										onFocus={onAnyFocus}
										onBlur={onAnyBlur}
										hideClearIcon
										autoFocus
										inputElement={DictionaryInput}
									/>
								</Box>
							</Flex>
						</Flex>
					);
				})}
		</Box>
	);
};

TranslationSubField.propTypes = {
	code: PropTypes.string.isRequired,
	disabled: PropTypes.bool,
	fieldArrayKey: PropTypes.string.isRequired,
	fieldComponent: PropTypes.node.isRequired,
	fieldName: PropTypes.string.isRequired,
	hasAnyLanguageCodes: PropTypes.bool,
	hidden: PropTypes.bool,
	id: PropTypes.string.isRequired,
	index: PropTypes.number.isRequired,
	isLast: PropTypes.bool,
	label: PropTypes.string.isRequired,
	labelProps: PropTypes.object,
	languages: PropTypes.array,
	onAnyBlur: PropTypes.func,
	onAnyFocus: PropTypes.func,
	otherTranslation0: PropTypes.string,
	removeLanguage: PropTypes.func,
	secondaryFieldComponent: PropTypes.node,
	secondaryFieldName: PropTypes.string,
	secondaryLabel: PropTypes.string,
	translationLabel: PropTypes.string,
	value: PropTypes.string.isRequired,
};
