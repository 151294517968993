import { includes, isNil, keys } from 'ramda';

import { useUserPrivileges } from '../../routes';
import { rafiModules, rafiRoleModules } from '../../constants';

export const useHasForModuleAction = (moduleName, actionName) => {
	const privileges = useUserPrivileges();
	if (isNil(moduleName)) {
		return true;
	}
	if (!includes(moduleName, keys({ ...rafiRoleModules, ...rafiModules }))) {
		return true;
	}
	return includes(`${actionName}_${moduleName}`, privileges);
};
