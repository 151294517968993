/* eslint-disable quotes */
import React from 'react';
import { Global, keyframes } from '@emotion/core';

import { usePrimaryColor } from '../../theme';

const preloaderLogoSizeLarge = '120px';
const preloaderSizeLarge = '160px';

const spin = keyframes`
  0% {
		transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const showspinner = keyframes`
  0% {
		opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const PreloaderStyles = () => {
	const primaryColor = usePrimaryColor();
	return (
		<Global
			styles={() => ({
				'.Preloader': {
					'&:before': {
						background: "url('/images/logo.png') no-repeat center center, rgba(255, 255, 255, 0.9)",
						backgroundSize: `${preloaderLogoSizeLarge} ${preloaderLogoSizeLarge}`,
					},
					'&:after': {
						marginLeft: `calc(-${preloaderSizeLarge} / 2)`,
						marginTop: `calc(-${preloaderSizeLarge} / 2)`,
						width: preloaderSizeLarge,
						height: preloaderSizeLarge,
						boxShadow: `2px 1px 1px ${primaryColor}`,
					},
				},
				'.Preloader--delayed': {
					'.Preloader:before': {
						opacity: 0,
						animation: `${showspinner} 0.5s forwards`,
						animationDelay: '0.5s',
					},
					'.Preloader:after': {
						opacity: 0,
						animation: `${showspinner} 0.5s forwards, ${spin} linear 1s infinite`,
						animationDelay: '0.5s',
					},
				},
			})}
		/>
	);
};
