import { always, ifElse } from 'ramda';
import { isArray, isNotEmpty, isString } from 'ramda-extension';
import axios from 'axios';

import { uploadImageForModule } from '../images';
import { FEATURED_TEXTBOOKS, TEXTBOOKS } from '../../constants/urls';
import { dataP, isNotNilOrEmpty, createPageableRequest, dataDataPath } from '../../utils';

export const getAllTextBooks = (name, languages) =>
	axios
		.get(TEXTBOOKS, {
			params: {
				limit: Number.MAX_SAFE_INTEGER,
				...name,
				...(isArray(languages) && isNotEmpty(languages) ? { languages } : {}),
				...(isString(languages) ? { language: languages } : {}),
			},
		})
		.then(dataDataPath);

export const geAllTextbooksById = (_, ids) =>
	ifElse(
		isNotNilOrEmpty,
		(ids) => axios.get(TEXTBOOKS, { params: { textbookIds: ids, sortBy: 'ids' } }).then(dataDataPath),
		always(Promise.resolve([]))
	)(ids);

export const getSingleTextBook = (/* api cache key */ _, id) => axios.get(`${TEXTBOOKS}/${id}`).then(dataP);

export const getPageableTextbooks = createPageableRequest(TEXTBOOKS, 'textbookId');

export const createTextbook = (textbook) => axios.post(TEXTBOOKS, { textbook }).then(dataP);

export const deleteTextbook = (id) => axios.delete(`${TEXTBOOKS}/${id}`).then(dataP);

export const deleteTextbooks = (textbookIds) => axios.delete(TEXTBOOKS, { params: { ids: textbookIds } }).then(dataP);

export const getFeaturedTextbooks = () => axios.get(FEATURED_TEXTBOOKS).then(dataP);

export const createFeaturedTextbooks = (featuredTextbooks) =>
	axios.put(FEATURED_TEXTBOOKS, { featuredTextbooks }).then(dataP);

export const editTextbook = (textbook, id) => axios.put(`${TEXTBOOKS}/${id}`, { textbook }).then(dataP);

export const uploadTextBookImage = uploadImageForModule(TEXTBOOKS);

export const deleteDeckInTextbook = ({ deckId, textbookId }) =>
	axios.delete(`${TEXTBOOKS}/decks/${textbookId}`, { params: { deckId } }).then(dataP);

export const getTextBookPublished = (/* api cache key */ _, id) => axios.get(`${TEXTBOOKS}/${id}/publish`).then(dataP);
export const publishTextBook = (id) => axios.put(`${TEXTBOOKS}/${id}/publish`).then(dataP);
export const unpublishTextBook = (id) => axios.put(`${TEXTBOOKS}/${id}/unpublish`).then(dataP);
export const prePublishTextBook = (id) => axios.put(`${TEXTBOOKS}/${id}/prepublish`).then(dataP);
export const unPrePublishTextBook = (id) => axios.put(`${TEXTBOOKS}/${id}/unprepublish`).then(dataP);
