import React, { useCallback, useMemo, memo, useState } from 'react';
import PropTypes from 'prop-types';
import { assoc, head, map, filter, o, last, toPairs, compose } from 'ramda';
import { isNilOrEmpty, isTruthy } from 'ramda-extension';

import { FormErrorContext } from './context';
import { isNotNilOrEmpty } from '../../utils';

export const FormErrorProvider = memo(({ children, formName }) => {
	const [sectionErrors, setSectionErrors] = useState({});
	const setSectionError = useCallback(
		(sectionName, hasError = false) => {
			if (isNotNilOrEmpty()) {
				setSectionErrors(assoc(sectionName, hasError));
			} else {
				console.warn(`You just tried to set ${formName} form error using undefined section name. Be careful`);
			}
		},
		[formName]
	);
	const getErrorSectionNames = useCallback(
		() => compose(map(head), filter(o(isTruthy, last)), toPairs)(sectionErrors),
		[sectionErrors]
	);
	const value = useMemo(
		() => ({
			sectionErrors,
			getErrorSectionNames,
			setSectionError,
		}),
		[sectionErrors, getErrorSectionNames, setSectionError]
	);
	if (isNilOrEmpty(formName)) {
		return children;
	}
	return <FormErrorContext.Provider value={value}>{children}</FormErrorContext.Provider>;
});

FormErrorProvider.displayName = 'memo(FormErrorProvider)';

FormErrorProvider.propTypes = {
	children: PropTypes.any,
	formName: PropTypes.string.isRequired,
};
