import { select, textField } from './parts/fields';
import { buttons } from './parts/buttons';

export const swissTheme = {
	colors: {
		primary: '#DD6B20',
		menuBackground: '#FFFFFF',
		background: '#F7FAFC',
		textPrimary: '#4A5568',
		focusBorder: '#718096',
		orange: {
			100: '#FFFAF0',
			200: '#FEEBC8',
			300: '#FBD38D',
			600: '#DD6B20',
			700: '#C05621',
			800: '#9C4221',
		},
		blue: {
			500: '#4299E1',
			700: '#2B6CB0',
		},
		green: {
			200: '#C6F6D5',
			500: '#48BB78',
			800: '#276749',
		},
		teal: {
			200: '#B2F5EA',
			600: '#319795',
			800: '#285E61',
		},
		red: {
			100: '#FFF5F5',
			200: '#FED7D7',
			300: '#FEB2B2',
			500: '#F56565',
			600: '#E53E3E',
			800: '#9B2C2C',
		},
		gray: {
			100: '#F7FAFC',
			200: '#EDF2F7',
			300: '#E2E8F0',
			400: '#CBD5E0',
			500: '#A0AEC0',
			600: '#718096',
			700: '#4A5568',
			900: '#1A202C',
		},
		yellow: {
			200: '#FEFCBF',
			300: '#FAF089',
			700: '#B7791F',
		},
		error: '#F00',
		success: '#00E676',
		disabled: 'hsl(0,0%,95%)',
	},
	fonts: {
		body: 'Inter',
		heading: 'Inter',
		monospace: 'Inter',
	},
	buttons,
	forms: {
		fields: {
			select,
			textField,
		},
	},
	sizes: {
		sidebar: 256,
	},
	text: {
		heading: {
			fontSize: 24,
			lineHeight: '36px',
			fontFamily: 'Inter',
		},
	},
	badges: {
		circle: {
			backgroundColor: 'gray.600',
			borderRadius: 999,
			width: '20px',
			height: '20px',
			textAlign: 'center',
			paddingTop: '3px',
			position: 'absolute',
		},
	},
	styles: {
		root: {
			fontFamily: 'body',
		},
		pre: {
			'.fast-option-focused': {
				backgroundColor: 'rgba(221,107,32,0.7)',
			},
			'.fast-option-selected': {
				backgroundColor: 'primary',
			},
		},
	},
};
