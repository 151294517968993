import React from 'react';
import PropTypes from 'prop-types';
import { Box, Flex } from 'rebass';

export const Icon = ({ color = 'inherit', fontSize = 4, disabled = false, icon, sx, ...other }) => (
	<Box
		sx={{
			cursor: disabled ? 'not-allowed' : 'pointer',
			'& > div': {
				fontSize: fontSize,
				color,
			},
			...sx,
		}}
		{...other}
	>
		<Flex alignItems="center" justifyContent="center" height="100%" width="100%">
			{icon}
		</Flex>
	</Box>
);

Icon.propTypes = {
	color: PropTypes.string,
	disabled: PropTypes.bool,
	fontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	icon: PropTypes.element,
	sx: PropTypes.object,
};
