import React, { useState } from 'react';
import { useScrollToTopOnLocationChange } from './hooks';
import { Menu } from './Menu';

export const MenuWrapper = () => {
	useScrollToTopOnLocationChange();
	const [showMenu, setShowMenu] = useState(null);

	return <Menu hideLabel={showMenu} active={showMenu} setShowMenu={setShowMenu} />;
};

MenuWrapper.propTypes = {};
