import { useCallback, useContext, useEffect } from 'react';

import { mergeDeepRight } from 'ramda';
import { AuthContext } from './authContext';
import { PERSIST_AUTH_KEY } from './constants';
import { emptyArray } from '../../constants';
import { isNotNilOrEmpty } from '../../utils';

export const useLogout = () => {
	const { setCredentials } = useContext(AuthContext);
	useEffect(() => {
		setTimeout(
			() =>
				window.gapi.load('auth2', function () {
					// eslint-disable-next-line no-unused-expressions
					window?.gapi?.auth2?.init();
				}),
			300
		);
	}, []);

	return useCallback(() => {
		setCredentials(null);
		localStorage.removeItem(PERSIST_AUTH_KEY);
		// eslint-disable-next-line no-unused-expressions
		window?.gapi?.auth2?.getAuthInstance?.()?.signOut?.();
	}, [setCredentials]);
};

export const useMergeCredentials = () => {
	const { credentials, setCredentials } = useContext(AuthContext);
	return useCallback(
		(updatedCredentials) => {
			const mergedCredentials = mergeDeepRight(credentials, updatedCredentials);
			localStorage.setItem(PERSIST_AUTH_KEY, JSON.stringify(mergedCredentials));
			setCredentials(mergedCredentials);
		},
		[credentials, setCredentials]
	);
};

export const useUserFullName = () => {
	const { credentials } = useContext(AuthContext);
	return credentials?.name ?? 'Anonymní uživatel';
};

export const useUserRoleId = () => {
	const { credentials } = useContext(AuthContext);
	return credentials?.roleId;
};

export const useUserPrivileges = () => {
	const { credentials } = useContext(AuthContext);
	return credentials?.privileges ?? emptyArray;
};

export const useIsAuthenticated = () => {
	const { credentials } = useContext(AuthContext);
	return isNotNilOrEmpty(credentials);
};

export const useUserId = () => {
	const { credentials } = useContext(AuthContext);
	return credentials?.userId;
};
