import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Box } from 'rebass';
import ReactSelect from 'react-select';
import { components as selectComponents } from 'react-select';

import { FormErrorText } from './FormErrorText';
import { REACT_SELECT_CLASSNAME_PREFIX } from './ReactSelect';
import { emptyArray, emptyObject } from '../../constants';
import { transformStringOptions } from '../../utils';

export const Select = ({
	options = emptyArray,
	placeholder = '',
	variant = 'primary',
	sx = emptyObject,
	errorMessage,
	autoFocus = false,
	selectComponent: SelectComponent = ReactSelect,
	innerRef,
	components = {},
	isDisabled,
	...other
}) => {
	const transformedOptions = useMemo(() => transformStringOptions(options), [options]);
	return (
		<FormErrorText errorMessage={errorMessage}>
			<Box sx={{ minWidth: '140px', variant: `forms.fields.select.${variant}`, ...sx }}>
				<SelectComponent
					isSearchable={false}
					placeholder={placeholder}
					options={transformedOptions}
					classNamePrefix={REACT_SELECT_CLASSNAME_PREFIX}
					autoFocus={autoFocus}
					components={{
						...components,
						DropdownIndicator: isDisabled ? null : selectComponents.DropdownIndicator,
					}}
					isDisabled={isDisabled}
					ref={innerRef}
					{...other}
				/>
			</Box>
		</FormErrorText>
	);
};

Select.propTypes = {
	autoFocus: PropTypes.bool,
	components: PropTypes.object,
	errorMessage: PropTypes.string,
	innerRef: PropTypes.node,
	isDisabled: PropTypes.bool,
	options: PropTypes.array,
	placeholder: PropTypes.string,
	selectComponent: PropTypes.node,
	sx: PropTypes.object,
	variant: PropTypes.string,
};
