import React from 'react';
import PropTypes from 'prop-types';
import { Box } from 'rebass';

import { rebassPropTypes } from '../../propTypes';

export const SvgIcon = ({ color, strokeColor, size, children, ...other }) => (
	<Box width={size} fontSize={20} sx={{ '& svg': { fill: color, stroke: strokeColor ?? color } }} {...other}>
		{children}
	</Box>
);

SvgIcon.propTypes = {
	...rebassPropTypes,
	children: PropTypes.node,
	color: PropTypes.string,
	size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};
