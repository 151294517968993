import React from 'react';
import { Box, Flex } from 'rebass';
import ReactLoaderSpinner from 'react-loader-spinner';

import { usePrimaryColor } from '../../theme';

export const FormLoader = () => {
	const primaryColor = usePrimaryColor();
	return (
		<Box
			width="100%"
			height="100vh"
			sx={{
				position: 'fixed',
				top: 0,
				left: 0,
				zIndex: 99,
				backgroundColor: 'rgba(255, 255, 255, 0.4)',
			}}
		>
			<Flex width="100%" height="100%" justifyContent="center" alignItems="center">
				<ReactLoaderSpinner type="TailSpin" color={primaryColor} height={80} width={80} />
			</Flex>
		</Box>
	);
};
