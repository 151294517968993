const common = {
	fontFamily: 'Inter',
	border: '1px solid',
	borderRadius: 3,
	fontWeight: 700,
	'&:focus': {
		outline: 'none',
		borderColor: 'gray.700',
	},
	cursor: 'pointer',
};

export const buttons = {
	primary: {
		...common,
		color: 'white',
		bg: 'primary',
		borderColor: 'primary',
	},
	secondary: {
		...common,
		color: 'primary',
		bg: 'white',
		borderColor: 'gray.400',
		'&:active, &:focus': {
			outline: 'none',
			borderColor: 'primary',
		},
	},
	secondaryWithBorder: {
		...common,
		color: 'primary',
		bg: 'white',
		borderColor: 'primary',
		'&:active, &:focus': {
			outline: 'none',
			borderColor: 'primary',
		},
	},
	outline: {
		...common,
		color: 'gray.700',
		bg: 'white',
		borderColor: 'gray.400',
		'&:active': {
			outline: 'none',
			borderColor: 'gray.700',
		},
	},
	success: {
		...common,
		color: 'white',
		bg: 'green.500',
		borderColor: 'transparent',
		borderRadius: '4px',
		fontSize: '14px',
	},
	disabled: {
		...common,
		color: 'gray.200',
		bg: 'gray.500',
		borderColor: 'transparent',
		borderRadius: '4px',
		fontSize: '14px',
		cursor: 'not-allowed',
	},
};
