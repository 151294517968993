import { path, propEq } from 'ramda';

export const getTargetValue = path(['target', 'value']);

export const preventEventDefault = (e) => {
	e.preventDefault();
};

export const stopEventPropagation = (e) => {
	e.stopPropagation();
};

export const preventEventDefaultAndStopPropagation = (e) => {
	stopEventPropagation(e);
	preventEventDefault(e);
};

export const isEnterKey = propEq('key', 'Enter');

export const isSpacebarKey = propEq('keyCode', 32);

export const isDeleteKey = propEq('keyCode', 8);
