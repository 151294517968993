import React from 'react';
import PropTypes from 'prop-types';
import { Flex, Text } from 'rebass';

export const FormErrorText = ({ children, errorMessage }) => (
	<Flex flexDirection="column" width="100%">
		{children}
		{errorMessage ? (
			<Text color="red.500" fontSize={1} my={1}>
				{errorMessage}
			</Text>
		) : null}
	</Flex>
);

FormErrorText.propTypes = {
	children: PropTypes.oneOfType([PropTypes.node, PropTypes.element]),
	errorMessage: PropTypes.string,
};
