import { useEffect } from 'react';
import { includes, find, prop, __, compose, values, keys, converge, zipObj, any } from 'ramda';
import { flipIncludes } from 'ramda-extension';
import { useLocation, useNavigate } from '@reach/router';

import { useUserPrivileges } from './context/hooks';
import { FORGOTTEN_PASSWORD_PATH, INVITATION_PATH } from './constants';
import { isNotNilOrEmpty } from '../utils';
import { privilegesAction, rafiModules } from '../constants';
import { rafiModuleKeys } from '../pages/RolePrivilegesTableEditor/table/utils';

export const rafiModuleRouteMap = {
	[rafiModules.ROLES]: '/settings/roles',
	[rafiModules.TAGS]: '/settings/tags',
	[rafiModules.CARDS]: '/cards',
	[rafiModules.USERS]: '/users',
	[rafiModules.DECKS]: '/decks',
	[rafiModules.APP_USERS]: '/app_users/users',
	[rafiModules.TEXTBOOKS]: '/textbooks',
	[rafiModules.PUBLISHERS]: '/settings/publishers',
	[rafiModules.DIFFICULTIES]: '/settings/difficulties',
	[rafiModules.WORD_TYPES]: '/settings/word-types',
	[rafiModules.MULTI_FORM]: '/multi_form',
	[rafiModules.LANGUAGES]: '/settings/languages',
	[rafiModules.FEATURED_TEXTBOOK]: '/settings/featured-textbook',
};

export const rafiRouteModuleMap = converge(zipObj, [values, keys])(rafiModuleRouteMap);

const createViewPrivilegeActionForModule = (module) => `${privilegesAction.VIEW}_${module}`;

const getFirstViewableRafiModule = (privileges) =>
	find((rafiModule) => includes(createViewPrivilegeActionForModule(rafiModule), privileges))(rafiModuleKeys);

const getMatchingPathModule = (pathname) =>
	compose(prop(__, rafiRouteModuleMap), find(flipIncludes(pathname)), keys)(rafiRouteModuleMap);

const getHasPrivilegeForCurrentPathName = (pathModule, privileges) =>
	any(includes(createViewPrivilegeActionForModule(pathModule)), privileges);

export const useNavigationForFirstVisibleModule = () => {
	const navigate = useNavigate();
	const privileges = useUserPrivileges();
	const { pathname } = useLocation();
	useEffect(() => {
		const firstViewableRafiModule = getFirstViewableRafiModule(privileges);
		if (
			isNotNilOrEmpty(firstViewableRafiModule) &&
			pathname !== INVITATION_PATH &&
			pathname !== FORGOTTEN_PASSWORD_PATH
		) {
			const hasPrivilegeForRootPathname = getHasPrivilegeForCurrentPathName(rafiModules.WORDS, privileges);
			if (!hasPrivilegeForRootPathname && pathname === '/') {
				const moduleNamePathname = rafiModuleRouteMap[firstViewableRafiModule];
				navigate(moduleNamePathname);
			}
			const pathModule = getMatchingPathModule(pathname);
			const hasPrivilegeForCurrentPathName = getHasPrivilegeForCurrentPathName(pathModule, privileges);
			if (!hasPrivilegeForCurrentPathName) {
				if (firstViewableRafiModule === rafiModules.WORDS) {
					navigate('/');
				} else {
					const moduleNamePathname = rafiModuleRouteMap[firstViewableRafiModule];
					navigate(moduleNamePathname);
				}
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [JSON.stringify(privileges)]);
};
