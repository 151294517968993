/* eslint-disable react/prop-types */
import React, { useCallback, memo } from 'react';
import PropTypes from 'prop-types';
import { animation, Menu, Item } from 'react-contexify';

import { IGNORED_WORDS_MAP_KEY } from '../constants';
import { safeJSONParse } from '../../../utils';

export const DictionaryContextMenu = memo(({ id, rerender }) => {
	const onClick = useCallback(
		({ event, props }) => {
			event.preventDefault();
			event.stopPropagation();
			event.stopImmediatePropagation();
			const currentIgnoredWordsMap = safeJSONParse(localStorage.getItem(IGNORED_WORDS_MAP_KEY)) ?? {};
			localStorage.setItem(
				IGNORED_WORDS_MAP_KEY,
				JSON.stringify({
					...currentIgnoredWordsMap,
					[props.languageCode]: [...(currentIgnoredWordsMap[props.languageCode] ?? []), props.word],
				})
			);
			rerender(props.word);
		},
		[rerender]
	);
	return (
		<Menu id={id} animation={animation.zoom}>
			<Item onClick={onClick}>Přidat do slovníku</Item>
			<Item onClick={onClick}>Ignorovat</Item>
		</Menu>
	);
});

DictionaryContextMenu.propTypes = {
	id: PropTypes.string,
	rerender: PropTypes.func,
};
