import React from 'react';
import PropTypes from 'prop-types';
import { Text } from 'rebass';
import ReactTippyTooltip from '@tippyjs/react';

export const Tooltip = ({
	children,
	fontSize = '10px',
	position = 'bottom',
	offsetLeft = 0,
	offsetBottom = 14,
	label,
	...other
}) => (
	<ReactTippyTooltip
		theme="dark"
		duration={100}
		offset={[offsetLeft, offsetBottom]}
		position={position}
		animation="fade"
		trigger="mouseenter"
		arrow={false}
		{...other}
		content={
			<Text fontSize={fontSize} color="white">
				{label}
			</Text>
		}
	>
		<div>{children}</div>
	</ReactTippyTooltip>
);

Tooltip.propTypes = {
	children: PropTypes.node,
	fontSize: PropTypes.string,
	label: PropTypes.string,
	offsetBottom: PropTypes.number,
	offsetLeft: PropTypes.number,
	position: PropTypes.string,
};
