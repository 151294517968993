import React from 'react';

import { PublicRoutes } from './PublicRoutes';
import { ProtectedRoutes } from './ProtectedRoutes';
import { useIsAuthenticated } from './context/hooks';

export const Routes = () => {
	const isAuthenticated = useIsAuthenticated();
	return isAuthenticated ? <ProtectedRoutes /> : <PublicRoutes />;
};
