import { compose, find, prop, propEq } from 'ramda';
import { languageLabels, wordTypesLabels } from './constants';
import { valueP } from '../../utils';
import { BASE_LANGUAGE } from '../../constants';

export const transformLabeledLanguages = ({ label }) => ({
	value: label,
	label: prop(label, languageLabels),
});

export const transformLabeledLanguagesWithValue = ({ label, value }) => ({
	value: value,
	label: prop(label, languageLabels),
});

export const transformLabeledLanguagesWithCzech = ({ label }) => ({
	value: label,
	label: `${prop(label, languageLabels)} -> Čeština`,
});

export const transformWordTypes = ({ label, value }) => ({
	value: value,
	label: prop(label, wordTypesLabels),
});

export const findBaseLanguageId = compose(valueP, find(propEq('label', BASE_LANGUAGE)));
