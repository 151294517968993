import React from 'react';
import PropTypes from 'prop-types';
import { MdHelpOutline } from 'react-icons/md';

import { Tooltip } from './Tooltip';
import { Icon } from '../Icon';

export const InfoTip = ({ label, offsetLeft, offsetBottom, fontSize = 16 }) => (
	<Tooltip label={label} fontSize="14px" position="right" offsetLeft={offsetLeft} offsetBottom={offsetBottom}>
		<Icon fontSize={fontSize} color="primary" icon={<MdHelpOutline />} />
	</Tooltip>
);

InfoTip.propTypes = {
	fontSize: PropTypes.number,
	label: PropTypes.string,
	offsetBottom: PropTypes.number,
	offsetLeft: PropTypes.number,
};
