import React from 'react';
import PropTypes from 'prop-types';
import { Box, Flex, Text } from 'rebass';
import { MdSettings } from 'react-icons/md';

import { useTranslation } from 'react-i18next';
import { Submenu } from './Submenu';
import { MenuItem } from './MenuItem';
import { UserItem } from './UserItem';
import { SubmenuItem } from './SubmenuItem';

import { SIDE_MENU_SMALL_WIDTH, SIDE_MENU_WIDTH } from './constants';
import { rafiModules } from '../../constants';
import { ReactComponent as UsersIcon } from '../../icons/i_users.svg';
import { ReactComponent as TextbookIcon } from '../../icons/i_textbook_creator.svg';
import { ReactComponent as CardsIcon } from '../../icons/i_cards.svg';
import { MenuHamburger } from './MenuHamburger';

export const Menu = ({ active, setShowMenu, hideLabel = false }) => {
	const { t } = useTranslation('navigation');
	return (
		<Box
			sx={{
				top: 0,
				minHeight: '100vh',
				flex: '0 0 auto',
				bg: 'menuBackground',
				width: active ? SIDE_MENU_SMALL_WIDTH : SIDE_MENU_WIDTH,
				borderRight: 'solid 1px',
				borderColor: 'gray.300',
				zIndex: 99,
			}}
		>
			<Flex minHeight="100vh" flexDirection="column" justifyContent="space-between">
				<Box>
					<Flex flexDirection="column">
						<Flex ml={24} my={18}>
							{!hideLabel && <img src="/images/sg-vertical.svg" alt="Logo" height={40} width={200} />}
						</Flex>
						<UserItem hideLabel={hideLabel} />
					</Flex>
					{window.location.hostname === 'admin.dev.rafifox.com' ? (
						<Flex width="100%" justifyContent="center" my={3}>
							<Text fontSize={1} color="red.500" fontWeight="bold" sx={{ textTransform: 'uppercase' }}>
								{!hideLabel ? 'Testovací instance' : '\0'}
							</Text>
						</Flex>
					) : null}
					<MenuItem
						module={rafiModules.TEXTBOOKS}
						label={t('navigation.mainNavBar.textbooks')}
						icon={<TextbookIcon />}
						toUrl="/textbooks"
						hideLabel={hideLabel}
						isDefault
					/>
					{/*<MenuItem*/}
					{/*	module={rafiModules.DECKS}*/}
					{/*	label="Balíčky"*/}
					{/*	icon={<PackagesIcon />}*/}
					{/*	toUrl="/decks"*/}
					{/*	hideLabel={hideLabel}*/}
					{/*/>*/}
					<MenuItem
						module={rafiModules.CARDS}
						label={t('navigation.mainNavBar.decks')}
						icon={<CardsIcon />}
						toUrl="/cards"
						hideLabel={hideLabel}
					/>
					<MenuItem
						module={rafiModules.USERS}
						label={t('navigation.mainNavBar.users')}
						icon={<UsersIcon />}
						toUrl="/users"
						hideLabel={hideLabel}
					/>
					<Submenu
						label={t('navigation.mainNavBar.options.label')}
						icon={<MdSettings />}
						toUrl="/settings"
						hideLabel={hideLabel}
					>
						<SubmenuItem
							module={rafiModules.FEATURED_TEXTBOOK}
							label={t('navigation.mainNavBar.options.subOption.recommended')}
							toUrl="/settings/featured-textbook"
						/>
						<SubmenuItem
							module={rafiModules.PUBLISHERS}
							label={t('navigation.mainNavBar.options.subOption.publishers')}
							toUrl="/settings/publishers"
						/>
						<SubmenuItem
							module={rafiModules.LANGUAGES}
							label={t('navigation.mainNavBar.options.subOption.supportedLanguages')}
							toUrl="/settings/languages"
						/>
					</Submenu>
				</Box>
				<MenuHamburger setShowMenu={setShowMenu} active={active} />
			</Flex>
		</Box>
	);
};

Menu.propTypes = {
	active: PropTypes.bool,
	hideLabel: PropTypes.bool,
	setShowMenu: PropTypes.func,
};
