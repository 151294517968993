import React, { forwardRef, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { equals, isNil } from 'ramda';
import { noop } from 'ramda-extension';
import { Button as RebassButton, Flex } from 'rebass';

import { buttonIconPositions } from './constants';
import { Icon } from '../Icon';
import { rebassPropTypes } from '../../propTypes';
import { FormDisabledContext } from '../../containers/Forms';

const iconPositionEqRight = equals(buttonIconPositions.RIGHT);

export const Button = forwardRef(
	(
		{
			label,
			icon,
			iconPosition = buttonIconPositions.RIGHT,
			onUnmount,
			disabled,
			variant,
			onClick,
			disableGlobalSubmit = false,
			...other
		},
		ref
	) => {
		const isIconRight = iconPositionEqRight(iconPosition);
		const wrappedIcon = <Icon icon={icon} />;
		const disableSubmit = useContext(FormDisabledContext);

		useEffect(
			() => () => {
				if (onUnmount) {
					return onUnmount();
				}
			},
			[onUnmount]
		);
		return (
			<RebassButton
				fontSize={1}
				tabIndex={0}
				height="40px"
				type="button"
				ref={ref}
				onFocus={() => disableGlobalSubmit && disableSubmit && disableSubmit(true)}
				onBlur={() => disableGlobalSubmit && disableSubmit && disableSubmit(false)}
				variant={disabled ? 'disabled' : variant}
				onClick={disabled ? noop : onClick}
				{...other}
			>
				{isNil(icon) ? (
					label
				) : (
					<Flex alignItems="center" justifyContent="center">
						{!isIconRight && wrappedIcon}
						{label}
						{isIconRight && wrappedIcon}
					</Flex>
				)}
			</RebassButton>
		);
	}
);

Button.propTypes = {
	...rebassPropTypes,
	disableGlobalSubmit: PropTypes.bool,
	icon: PropTypes.element,
	iconPosition: PropTypes.oneOf([buttonIconPositions.LEFT, buttonIconPositions.RIGHT]),
};
