import { filter, isNil, prop, uniqBy } from 'ramda';
import axios from 'axios';
import debouncePromise from 'debounce-promise';

import { dataP, isNotNilOrEmpty } from './ramda';

export const defaultCreatePageableSearchParams = ({ orderBy, orderDirection, search }) => {
	const { term, language, after, sortDirection, sortBy } = search;
	const sortParams = {
		sortBy: sortBy?.field ?? orderBy?.field,
		sortOrder: sortDirection ?? orderDirection ?? sortDirection,
	};
	const searchParams = { term, language: language?.value };
	const pageParams = { after };
	return { params: filter(isNotNilOrEmpty, { ...sortParams, ...searchParams, ...pageParams }) };
};

export const createPageableRequest = (
	module,
	uniqIdProp = 'id',
	createPageableSearchParams = defaultCreatePageableSearchParams
) => {
	let dataCache = [];
	let prefetchedAfterKeysCache = {};
	return debouncePromise((pageableParams) => {
		const after = pageableParams?.search?.after;
		if (isNil(after)) {
			dataCache = [];
			prefetchedAfterKeysCache = {};
		} else {
			prefetchedAfterKeysCache[after] = after;
		}
		return axios.get(module, createPageableSearchParams(pageableParams)).then(({ data }) => {
			dataCache =
				[...dataCache, ...(dataP(data) ?? [])].map((row) => ({
					...row,
					after: data.after > data.totalCount ? data.totalCount : data.after,
					afterCacheString: JSON.stringify(prefetchedAfterKeysCache),
				})) ?? [];
			if (uniqIdProp) {
				dataCache = uniqBy(prop(uniqIdProp), dataCache);
			} else {
				console.warn(`Warning: ${uniqIdProp} from pageable module ${module} is null. Please check method factory`);
			}
			return {
				data: dataCache,
				totalCount: data.totalCount,
			};
		});
	}, 200);
};
