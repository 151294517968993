import { useCallback, useContext, useMemo } from 'react';
import { CachedSelectContext } from './context';

export const useGetSelectCacheValue = (fieldName) => {
	const { get } = useContext(CachedSelectContext);
	return useMemo(() => get(fieldName), [get, fieldName]);
};

export const useSetCachedSelectValue = (fieldName) => {
	const { set } = useContext(CachedSelectContext);
	return useCallback((value) => set(fieldName, value), [set, fieldName]);
};

export const useCachedSelect = (fieldName) => {
	const value = useGetSelectCacheValue(fieldName);
	const setValue = useSetCachedSelectValue(fieldName);
	return useMemo(() => [value, setValue], [value, setValue]);
};
