import React from 'react';
import PropTypes from 'prop-types';
import { MdChevronLeft } from 'react-icons/md';
import { Button } from './Button';
import { useNavigateBackWithCurrentId } from '../Editor';

export const BackButton = ({ hideBackButton }) => {
	const navigateBack = useNavigateBackWithCurrentId();
	return (
		<Button
			icon={<MdChevronLeft />}
			data-cy="backButton"
			color="primary"
			mr={16}
			width={40}
			height={40}
			onClick={navigateBack}
			sx={{
				padding: 0,
				borderRadius: '3px',
				borderColor: 'gray.300',
				bg: 'menuBackground',
				display: hideBackButton ? 'none' : 'block',
			}}
		/>
	);
};

BackButton.propTypes = {
	hideBackButton: PropTypes.bool,
};
