import { lteThanLength } from 'ramda-extension';
import sanitizeHtml from 'sanitize-html';

export const validateWysiwyg = (value) => lteThanLength(1, sanitizeHtml(value, { allowedTags: [] }));

const censor = (key, value) => {
	if (key === 'ref') {
		return null;
	} else {
		return value;
	}
};

export const getFirstFormError = (errors) => JSON.stringify(errors, censor).match(/message":"([^"]+)/)?.[1];
