import React from 'react';
import PropTypes from 'prop-types';
import { Box } from 'rebass';

import { Select } from './Select';

export const LanguageSelect = ({ variant, selectProps }) => (
	<Box minWidth={82} maxWidth={140}>
		<Select variant={variant} {...selectProps} />
	</Box>
);

LanguageSelect.propTypes = {
	selectProps: PropTypes.object,
	variant: PropTypes.string,
};
