import React from 'react';
import PropTypes from 'prop-types';
import { Box, Flex, Text } from 'rebass';
import { MdClear } from 'react-icons/md';

import { Icon } from '../Icon';

const variantColors = {
	success: 'green.500',
	error: 'red.500',
};

export const FormAlert = ({
	title = 'Formulář obsahuje chyby',
	message,
	isVisible,
	onClose,
	variant = 'error',
	...other
}) =>
	isVisible ? (
		<Box
			data-cy={'alert_' + variant}
			sx={{
				borderRadius: '3px',
				border: '1px solid',
				borderColor: 'gray.300',
				bg: 'menuBackground',
				borderLeft: '4px solid',
				borderLeftColor: variantColors[variant],
				position: 'relative',
				boxShadow: '0px 2px 3px #2D37481A',
				minHeight: '80px',
			}}
			py={3}
			px="20px"
			{...other}
		>
			{onClose && (
				<Icon
					sx={{ position: 'absolute', top: '8px', right: '8px' }}
					color="textPrimary"
					icon={<MdClear />}
					onClick={onClose}
				/>
			)}
			<Flex flexDirection="column">
				<Text mb={2} fontSize={1} fontWeight={700} color={variantColors[variant]} sx={{ textTransform: 'uppercase' }}>
					{title}
				</Text>
				<Text fontSize={1} color="gray.700">
					{message}
				</Text>
			</Flex>
		</Box>
	) : null;

FormAlert.propTypes = {
	isVisible: PropTypes.bool,
	message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
	onClose: PropTypes.func,
	title: PropTypes.string,
	variant: PropTypes.oneOf(['error', 'success']),
};
