import axios from 'axios';

import { createPageableRequest, dataP } from '../../../utils';
import { PRIVILEGES, ROLES } from '../../../constants/urls';

export const getPageableRoles = createPageableRequest(ROLES);

export const getRole = (roleId) => axios.get(`${ROLES}/${roleId}`).then(dataP);

export const createRole = (role) => axios.post(ROLES, role).then(dataP);

export const updateRolePrivileges = (id, privileges) => axios.post(PRIVILEGES, { id, privileges }).then(dataP);

export const deleteRole = (role) => axios.delete(`${ROLES}/${role.id}`);

export const editRole = (role) => axios.put(`${ROLES}/${role.id}`, { role }).then(dataP);
