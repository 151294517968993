import React from 'react';
import PropTypes from 'prop-types';
import { not } from 'ramda';
import { Flex } from 'rebass';
import { MdMenu } from 'react-icons/md';
import { PROFILE_BLOCK_HEIGHT } from '../../theme';
import { Icon } from '../Icon';

export const MenuHamburger = ({ setShowMenu }) => (
	<Flex
		px={24}
		alignItems="center"
		justifyContent="space-between"
		height={PROFILE_BLOCK_HEIGHT}
		sx={{ borderTop: '1px solid', borderColor: 'gray.300', userSelect: 'none' }}
	>
		<Icon icon={<MdMenu />} fontSize={20} color="gray.500" height={32} width={32} onClick={() => setShowMenu(not)} />
	</Flex>
);

MenuHamburger.propTypes = {
	setShowMenu: PropTypes.func,
};
