import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, juxt, o, when, map } from 'ramda';
import { containsAll, notFlipInclude } from 'ramda-extension';
import { Box, Flex, Text } from 'rebass';
import { useFieldArray, useFormContext } from 'react-hook-form';

import { useMultiLanguageField } from './hooks';
import { InvisibleSimpleUncontrolledField } from '../InvisibleSimpleUncontrolledField';
import { BASE_LANGUAGE, LANGUAGES_CODELIST_NAME } from '../../../constants';
import { Button } from '../../../components/Button';
import { rebassPropTypes } from '../../../propTypes';
import { EditorTreeIndicator } from '../../../components/Editor';
import { ClearableLanguageTextField, LanguageSelect } from '../../../components/Forms';
import { DictionaryInput } from '../../../features/dictionary/components/DictionaryControlledInput';
import {
	getTargetValue,
	isEnterKey,
	isNotNilOrEmpty,
	mapToString,
	pluckCode,
	preventEventDefault,
} from '../../../utils';
import { useRawCodelist } from '../../../features/codelist';
import { TranslationSubField } from './TranslationSubField';
import { TranslationField } from './TranslationField';

const TRANSLATION_BUTTON_FIELD_NAME = 'translationButton';
const REQUIRED_FIELD_NAME = 'requiredLanguages';

export const TranslationsBlock = ({
	label,
	languageComponent: LanguageComponent = LanguageSelect,
	localizedComponent: LocalizedComponent = ClearableLanguageTextField,
	component: Component = ClearableLanguageTextField,
	placeholder,
	fieldName,
	secondaryFieldName,
	secondaryFieldComponent,
	secondaryLabel,
	translationLabel,
	fieldDefaultValue,
	fieldIdName,
	fieldArrayKey,
	required,
	appendOnEnter = true,
	defaultPhrases,
	disabled = false,
	phraseId,
	fieldArrayFuncs,
	atleastOneTranslation,
	allowedLanguages,
	requiredLanguages,
	requiredLanguagesFieldName,
	labelProps,
	onAnyBlur,
	onAnyFocus,
	firstFieldAutoFocus,
	nextButtonAutoFocus,
	...other
}) => {
	const addNextRef = useRef();
	const { data: languages } = useRawCodelist(LANGUAGES_CODELIST_NAME);

	const { errors, clearErrors } = useFormContext();
	const fallBackfieldArrayFuncs = useFieldArray({
		name: fieldArrayKey,
	});
	const multiLanguageFunctions = fieldArrayFuncs ?? fallBackfieldArrayFuncs;

	useEffect(() => {
		if (addNextRef.current && !firstFieldAutoFocus && nextButtonAutoFocus) {
			addNextRef.current.focus();
		}
	}, [addNextRef, fieldDefaultValue, firstFieldAutoFocus, nextButtonAutoFocus]);

	const {
		fields,
		nextLanguageValue,
		setNextLanguageValue,
		appendLanguage,
		removeLanguage,
		nextMultiLanguageSelectProps,
		hasAnyLanguageCodes,
	} = useMultiLanguageField(multiLanguageFunctions, allowedLanguages);
	const stringLanguages = mapToString(allowedLanguages ?? []);
	return (
		<Box {...other} data-cy={fieldName}>
			<TranslationField
				labelProps={labelProps}
				fieldName={fieldName}
				label={label}
				component={Component}
				placeholder={placeholder}
				disabled={disabled}
				onAnyFocus={onAnyFocus}
				onAnyBlur={onAnyBlur}
				languageCode={BASE_LANGUAGE}
				inputElement={DictionaryInput}
				data-cy={`${fieldName}_0`}
				required={required}
				fieldDefaultValue={fieldDefaultValue}
				firstFieldAutoFocus={firstFieldAutoFocus}
				secondaryFieldName={secondaryFieldName}
				secondaryFieldComponent={secondaryFieldComponent}
				secondaryLabel={secondaryLabel}
			/>
			{fieldIdName && <InvisibleSimpleUncontrolledField name={fieldIdName} defaultValue={phraseId} />}
			<Box width={1} data-cy={`${fieldName}_fields`}>
				{fields.map(({ id, code, value, label, ...other }, index) => (
					<TranslationSubField
						key={id}
						code={code}
						disabled={disabled}
						fieldArrayKey={fieldArrayKey}
						fieldComponent={LocalizedComponent}
						fieldName={fieldName}
						hasAnyLanguageCodes={hasAnyLanguageCodes}
						id={id}
						index={index}
						isLast={index === fields.length - 1}
						label={label}
						translationLabel={translationLabel}
						secondaryLabel={secondaryLabel}
						labelProps={labelProps}
						languages={languages}
						onAnyBlur={onAnyBlur}
						onAnyFocus={onAnyFocus}
						removeLanguage={removeLanguage}
						secondaryFieldName={secondaryFieldName}
						secondaryFieldComponent={secondaryFieldComponent}
						value={value}
						hidden={isNotNilOrEmpty(stringLanguages) ? notFlipInclude(stringLanguages, code?.toString()) : false}
						{...other}
					/>
				))}
				{hasAnyLanguageCodes && !disabled && (
					<Flex width={1} alignItems="stretch">
						<EditorTreeIndicator isLast />
						<Flex width="100%" mt="12px">
							{atleastOneTranslation && (
								<InvisibleSimpleUncontrolledField
									name={fieldName + '-' + TRANSLATION_BUTTON_FIELD_NAME}
									rules={{
										validate: () => (isEmpty(fields) ? `${label} musí obsahovat alespoň jeden překlad` : undefined),
									}}
								/>
							)}
							{requiredLanguages && (
								<InvisibleSimpleUncontrolledField
									name={fieldName + '-' + REQUIRED_FIELD_NAME}
									rules={{
										validate: () =>
											containsAll(
												requiredLanguages,
												map((v) => v?.toString(), pluckCode(fields))
											)
												? undefined
												: `${label} musí obsahovat stejné jazyky jako ${requiredLanguagesFieldName}`,
									}}
								/>
							)}
							<Button
								ref={addNextRef}
								minWidth="140px"
								mr={2}
								variant="secondary"
								label="Přidat překlad"
								data-cy={`${fieldName}_add`}
								onClick={(e) => {
									preventEventDefault(e);
									appendLanguage();
									clearErrors([fieldName + '-' + TRANSLATION_BUTTON_FIELD_NAME, fieldName + '-' + REQUIRED_FIELD_NAME]);
								}}
							/>
							<Box data-cy={`${fieldName}_add_language`}>
								<LanguageComponent
									selectProps={nextMultiLanguageSelectProps}
									value={nextLanguageValue}
									onChange={o(setNextLanguageValue, getTargetValue)}
									onKeyDown={appendOnEnter && when(isEnterKey, juxt([preventEventDefault, appendLanguage]))}
								/>
							</Box>
							{errors[fieldName + '-' + TRANSLATION_BUTTON_FIELD_NAME] ? (
								<Flex ml={2} alignItems="center">
									<Text fontSize={1} color="red.500">
										{errors[fieldName + '-' + TRANSLATION_BUTTON_FIELD_NAME].message}
									</Text>
								</Flex>
							) : null}
							{errors[fieldName + '-' + REQUIRED_FIELD_NAME] ? (
								<Flex ml={2} alignItems="center">
									<Text fontSize={1} color="red.500">
										{errors[fieldName + '-' + REQUIRED_FIELD_NAME].message}
									</Text>
								</Flex>
							) : null}
						</Flex>
					</Flex>
				)}
			</Box>
		</Box>
	);
};

TranslationsBlock.propTypes = {
	...rebassPropTypes,
	allowedLanguages: PropTypes.array,
	appendOnEnter: PropTypes.bool,
	atleastOneTranslation: PropTypes.bool,
	disabled: PropTypes.bool,
	fieldArrayFuncs: PropTypes.object,
	fieldArrayKey: PropTypes.string,
	fieldDefaultValue: PropTypes.any,
	fieldIdName: PropTypes.string,
	fieldName: PropTypes.string,
	firstFieldAutoFocus: PropTypes.bool,
	label: PropTypes.string,
	labelProps: PropTypes.object,
	onAddLanguageCallback: PropTypes.func,
	onAnyBlur: PropTypes.func,
	onAnyFocus: PropTypes.func,
	placeholder: PropTypes.string,
	required: PropTypes.bool,
	requiredLanguages: PropTypes.array,
	requiredLanguagesFieldName: PropTypes.string,
	translationLabel: PropTypes.string,
};
