import React from 'react';
import PropTypes from 'prop-types';
import { isNotNil } from 'ramda-extension';
import { MdClear } from 'react-icons/md';

import { TextField } from './TextField';
import { SvgIcon } from '../Icon';
import { isNotNilOrEmpty } from '../../utils';

import { ReactComponent as SearchIcon } from '../../icons/i_search.svg';

export const SearchTextField = ({ onClear, value, ...props }) => (
	<TextField
		prefixElement={
			<SvgIcon size={28} ml={2} height="auto" color="gray.400">
				<SearchIcon />
			</SvgIcon>
		}
		suffixElement={
			isNotNil(onClear) && isNotNilOrEmpty(value) ? (
				<SvgIcon
					size={28}
					ml={2}
					mr={2}
					height="auto"
					sx={{ cursor: 'pointer', '& svg': { fill: 'gray.400' }, '&:hover svg': { fill: 'gray.600' } }}
					onClick={onClear}
				>
					<MdClear />
				</SvgIcon>
			) : null
		}
		value={value}
		{...props}
	/>
);

SearchTextField.propTypes = {
	onClear: PropTypes.func,
	value: PropTypes.string,
};
