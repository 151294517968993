/* eslint-disable quotes */
import React from 'react';
import PropTypes from 'prop-types';
import { Flex } from 'rebass';
import { Label } from '@rebass/forms';

import { InfoTip } from '../Tooltip';
import { isNotNilOrEmpty } from '../../utils';

export const FormLabel = ({ label, htmlFor, tooltip, ...other }) => {
	const hasTooltip = isNotNilOrEmpty(tooltip);
	return (
		<Flex flexDirection="flex-start" alignItems="center">
			<Label
				width={hasTooltip ? 'inherit' : undefined}
				my={1}
				mr={1}
				fontSize={1}
				color="gray.700"
				htmlFor={htmlFor}
				{...other}
			>
				{label}
			</Label>
			{hasTooltip ? <InfoTip label={tooltip} /> : null}
		</Flex>
	);
};

export const RequiredFormLabel = ({ ...other }) => (
	<FormLabel
		{...other}
		sx={{
			'&:after': {
				color: 'red.600',
				content: "'*'",
			},
		}}
	/>
);

FormLabel.propTypes = {
	htmlFor: PropTypes.string,
	label: PropTypes.string,
	tooltip: PropTypes.string,
};
