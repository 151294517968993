import { useCallback, useContext, useEffect, useState } from 'react';
import { mergeDeepLeft } from 'ramda';
import { applyIfNotNil, noop, notEqual } from 'ramda-extension';
import { useForm } from 'react-hook-form';

import { TableSearchContext } from './tableSearchContext';
import { isNotNilOrEmpty } from '../../../utils';

export const useSetTableSearchValue = () => {
	const { setSearch } = useContext(TableSearchContext) ?? { setSearch: noop };
	return useCallback((searchParams) => setSearch(mergeDeepLeft(searchParams)), [setSearch]);
};

export const useTableSearchValue = () => {
	const { search } = useContext(TableSearchContext) ?? {};
	return search;
};

export const useSearchValueChangeCallback = (cb) => {
	const searchValue = useTableSearchValue();
	const [previousSearchValue, setPreviousSearchValue] = useState(searchValue);
	useEffect(() => {
		if (notEqual(previousSearchValue, searchValue)) {
			applyIfNotNil(cb, []);
			setPreviousSearchValue(searchValue);
		}
	}, [cb, searchValue, previousSearchValue]);
};

export const useTableSearchForm = (onSearchChanged) => {
	const searchValue = useTableSearchValue();
	const [previousSearchValue, setPreviousSearchValue] = useState(searchValue);
	const formMethods = useForm({
		defaultValues: searchValue,
	});
	useEffect(() => {
		if (isNotNilOrEmpty(searchValue)) {
			applyIfNotNil(onSearchChanged, [searchValue]);
			setPreviousSearchValue(searchValue);
		}
	}, [onSearchChanged, searchValue, previousSearchValue]);
	return formMethods;
};
