import { createContext, useCallback, useContext } from 'react';
import { useNavigate } from '@reach/router';

export const LocationStateContext = createContext({});

export const useLocationState = () => {
	const { locationState } = useContext(LocationStateContext);
	return locationState;
};

export const useLocationStateSetter = () => {
	const { setLocationState } = useContext(LocationStateContext);
	return setLocationState;
};

export const useLocationModificationStateSetter = () => {
	const { setLocationState, locationState } = useContext(LocationStateContext);
	return useCallback((params = {}) => setLocationState({ ...locationState, ...params }), [
		setLocationState,
		locationState,
	]);
};

export const useClearFreshState = () => {
	const locationState = useLocationState();
	const setLocationState = useLocationStateSetter();
	return useCallback(() => setLocationState({ ...locationState, fresh: false }), [locationState, setLocationState]);
};

export const useNavigationWithState = () => {
	const navigate = useNavigate();
	const { setLocationState } = useContext(LocationStateContext);
	return useCallback(
		(url, state, options) => {
			setLocationState(state);
			navigate(url, options);
		},
		[navigate, setLocationState]
	);
};
