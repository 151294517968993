import { keyMirror } from 'ramda-extension';

export const imageCacheKeys = keyMirror({
	SINGLE_IMAGE: null,
	ALL_IMAGES: null,
});

export const IMAGE_UPLOAD_URL = '/image-upload-url';
export const PROCESS_IMAGE_UPLOAD_URL = '/process-image-upload';

export const WIKIMEDIA_URL = 'https://en.wikipedia.org/w/api.php';

export const PIXABAY_URL = '/pixabay/api/';

export const IMAGE_URL_PARTS = ['//cellar-c2.services.clever-cloud.com/', WIKIMEDIA_URL, PIXABAY_URL]

export const DO_NOT_INCLUDE_AUTH_HEADER = 'X-Do-Not-Include-Auth-Header'

export const supportedMimeTypes = ['image/jpeg', 'image/png', 'image/svg+xml'];

export const imageUrlDenyList = [
	'https://upload.wikimedia.org/wikipedia/commons/9/99/Wiktionary-logo-en-v2.svg',
	'https://upload.wikimedia.org/wikipedia/commons/8/89/Symbol_book_class2.svg',
	'https://upload.wikimedia.org/wikipedia/commons/d/df/Wikispecies-logo.svg',
	'https://upload.wikimedia.org/wikipedia/commons/d/df/Wikibooks-logo-en-noslogan.svg',
];
