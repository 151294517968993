import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { equals } from 'ramda';
import { noop } from 'ramda-extension';
import { Flex, Button, Text, Box } from 'rebass';
import sanitizeHtml from 'sanitize-html';

import { MdClear, MdAdd } from 'react-icons/md';
import { IoIosMedical } from 'react-icons/io';

import { preventEventDefault } from '../../../utils';
import { useBorderColor, usePrimaryColor } from '../../../theme';

export const ExtendedOptionalOption = memo(
	({ data, innerProps, isFocused, innerRef, isDisabled, label, index, optionHeight }) => {
		const { onClick, onMouseOver, onMouseMove, ...otherInnerProps } = innerProps;
		const secondaryText =
			data?.other?.description ??
			data?.description ??
			data?.phrase ??
			(isNaN(data?.deckCount) ? null : `Počet lekcí: ${data.deckCount}`);
		const sanitizedSecondaryText = sanitizeHtml(secondaryText ?? '', { allowedTags: [] });
		return (
			<Flex
				justifyContent="center"
				alignItems="center"
				data-index={index}
				{...otherInnerProps}
				ref={innerRef}
				onClick={data.disabled ? noop : onClick}
				sx={{
					cursor: data.disabled || isDisabled ? 'initial' : 'pointer',
					position: 'relative',
					height: `${optionHeight}px`,
					'&:hover': {
						backgroundColor: data.disabled || isDisabled ? 'transparent' : 'orange.100',
					},
				}}
				py={3}
			>
				{isFocused && (
					<Box
						sx={{
							height: '100%',
							left: 0,
							width: '5px',
							position: 'absolute',
							background: usePrimaryColor(),
							borderRadius: '5px',
						}}
					/>
				)}
				<Button
					onClick={preventEventDefault}
					sx={{
						borderColor: useBorderColor(),
						cursor: data.disabled || isDisabled ? 'default' : 'pointer',
						height: '24px',
						width: '24px',
					}}
					p={0}
					mx={2}
					variant="secondary"
				>
					<Flex
						sx={{
							color: data.disabled || isDisabled ? 'gray.400' : 'primary',
						}}
						alignItems="center"
						justifyContent="center"
					>
						{data.__isNew__ ? <IoIosMedical /> : data.disabled || isDisabled ? <MdClear /> : <MdAdd />}
					</Flex>
				</Button>
				<Flex width="100%" pr={32} flexDirection="column" sx={{ '& p': { margin: 0 } }}>
					<Flex alignItems="center" flexWrap="wrap">
						<Text fontSize={1} mr={1} color={data.disabled || isDisabled ? 'gray.500' : 'textPrimary'}>
							{label ?? data.label}
						</Text>
						<Text fontSize={10} color={data.disabled || isDisabled ? 'gray.400' : 'gray.500'}>
							{data.translations ? `(${data.translations?.join(', ')})` : ''}
						</Text>
					</Flex>
					{data?.publisher && (
						<Text fontSize={12} color="gray.500" sx={{ textOverflow: 'ellipsis' }}>
							Nakladatelství: {data?.publisher}
						</Text>
					)}
					<Text
						fontSize={12}
						color="gray.500"
						sx={{ textOverflow: 'ellipsis' }}
						dangerouslySetInnerHTML={{ __html: sanitizedSecondaryText }}
					/>
				</Flex>
			</Flex>
		);
	},
	({ data: prevData, isFocused: prevIsFocused }, { data: nextData, isFocused: nextIsFocused }) =>
		equals({ prevData, prevIsFocused }, { nextData, nextIsFocused })
);

ExtendedOptionalOption.propTypes = {
	data: PropTypes.shape({
		label: PropTypes.string,
		value: PropTypes.string,
		description: PropTypes.string,
		deckCount: PropTypes.number,
		disabled: PropTypes.bool,
		translations: PropTypes.string,
		other: PropTypes.shape({
			description: PropTypes.string,
			difficulty: PropTypes.string,
		}),
		phrase: PropTypes.string,
		publisher: PropTypes.string,
		__isNew__: PropTypes.bool,
	}),
	index: PropTypes.number,
	innerProps: PropTypes.object,
	innerRef: PropTypes.func,
	isDisabled: PropTypes.bool,
	isFocused: PropTypes.bool,
	label: PropTypes.string,
	optionHeight: PropTypes.number,
};
