import axios from 'axios';

import { dataP } from '../../utils';
import { CODELIST } from '../../constants/urls';
import { BASE_LANGUAGE } from '../../constants';

export const getRawCodelist = (codelistCacheKey, codelistName) => axios.get(`${CODELIST}/${codelistName}`).then(dataP);

export const getCodelist = (codelistCacheKey, codelistName) =>
	axios
		.get(`${CODELIST}/${codelistName}`)
		// TODO: This needs to go awayyy
		.then(({ data }) => data.filter(({ label }) => label !== BASE_LANGUAGE && label !== 'Český'));
