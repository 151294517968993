import React from 'react';
import PropTypes from 'prop-types';
import { Button as RebassButton, Flex } from 'rebass';
import ReactLoaderSpinner from 'react-loader-spinner';

import { rebassPropTypes } from '../../propTypes';

export const LoadingButton = ({ label, spinnerColor = 'white', size = 24, isLoading, ...other }) => (
	<RebassButton p={0} fontSize={1} tabIndex={0} height="40px" type="button" {...other}>
		<Flex alignItems="center" justifyContent="center">
			{isLoading ? <ReactLoaderSpinner type="TailSpin" color={spinnerColor} height={size} width={size} /> : label}
		</Flex>
	</RebassButton>
);

LoadingButton.propTypes = {
	...rebassPropTypes,
	isLoading: PropTypes.bool,
	size: PropTypes.number,
};
