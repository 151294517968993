import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { InteractiveHeadingContext } from './context';

export const InteractiveHeadingProvider = ({ children, initialValue }) => {
	const [headingValue, setHeadingValue] = useState(initialValue);
	return (
		<InteractiveHeadingContext.Provider value={{ setHeadingValue, headingValue }}>
			{children}
		</InteractiveHeadingContext.Provider>
	);
};

InteractiveHeadingProvider.propTypes = {
	children: PropTypes.node,
	initialValue: PropTypes.string,
};
