import { path } from 'ramda';
import { useThemeUI } from 'theme-ui';

export const useThemeColors = () => {
	const { theme } = useThemeUI();
	return theme.colors;
};

export const usePrimaryColor = () => {
	const colors = useThemeColors();
	return colors.primary;
};

export const useGrayShade = (shade) => {
	const colors = useThemeColors();
	return colors.gray[shade];
};

export const useOrangeShade = (shade) => {
	const colors = useThemeColors();
	return colors.orange[shade];
};

export const useBorderColor = () => useGrayShade(400);

export const useMenuBackground = () => {
	const colors = useThemeColors();
	return colors.menuBackground;
};

export const useBackground = () => {
	const colors = useThemeColors();
	return colors.background;
};

export const useTextPrimaryColor = () => {
	const colors = useThemeColors();
	return colors.textPrimary;
};

export const useFieldWrapperVariant = (variant) => {
	const { theme } = useThemeUI();
	return path(['forms', 'fieldWrapper', variant], theme);
};

export const useRedShade = (shade) => {
	const colors = useThemeColors();
	return colors.red[shade];
};

export const useDisabledColor = () => {
	const colors = useThemeColors();
	return colors.disabled;
};
