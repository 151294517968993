import {
	andThen,
	compose,
	pathOr,
	values,
	map,
	head,
	pluck,
	o,
	keys,
	converge,
	omit,
	identity,
	filter,
	propSatisfies,
	curry,
	isNil,
	path,
	reject,
} from 'ramda';
import { flipIncludes, isTruthy, startsWithPrefix } from 'ramda-extension';
import axios from 'axios';

import {
	IMAGE_UPLOAD_URL,
	imageUrlDenyList,
	PIXABAY_URL,
	PROCESS_IMAGE_UPLOAD_URL,
	supportedMimeTypes,
	WIKIMEDIA_URL,
} from './constants';
import { createFileUploadAxiosConfig } from './utils';
import { dataIdPath, dataP } from '../../utils';
import { ENGLISH_LANGUAGE_CODE } from '../../constants';
import { BASE_URL } from '../config';

export const getPixabayImages = (q, page, lang = ENGLISH_LANGUAGE_CODE) =>
	axios
		.get(PIXABAY_URL, {
			baseURL: BASE_URL,
			params: {
				q,
				page,
				lang,
				image_type: 'photo',
			},
		})
		.then(dataP);

let gimcontinue;

export const getWikimediaImages = (searchText, searchNext) =>
	axios
		.get(WIKIMEDIA_URL, {
			params: {
				origin: '*',
				action: 'query',
				generator: 'images',
				format: 'json',
				prop: 'imageinfo',
				gimlimit: '10',
				redirects: '1',
				titles: searchText,
				indexpageids: '',
				iilimit: '1',
				// iiurlwidth: '',
				// iiurlheight: '',
				iiprop: 'url|mime',
				...(isTruthy(searchNext) ? { gimcontinue } : {}),
			},
		})
		.then(({ data }) => {
			gimcontinue = path(['continue', 'gimcontinue'])(data);
			return compose(
				filter(propSatisfies(flipIncludes(supportedMimeTypes), 'mime')),
				reject(propSatisfies(flipIncludes(imageUrlDenyList), 'url')),
				map(head),
				pluck('imageinfo'),
				values,
				converge(omit, [o(reject(startsWithPrefix('-')), keys), identity]),
				pathOr([], ['query', 'pages'])
			)(data);
		});

const createPrefixedImageUploadUrl = (modulePrefix) => `${modulePrefix}${IMAGE_UPLOAD_URL}`;
const createPrefixedImageProcessUrl = (modulePrefix) => `${modulePrefix}${PROCESS_IMAGE_UPLOAD_URL}`;

export const getPreValidatedImageUploadData = compose(andThen(dataP), axios.post, createPrefixedImageUploadUrl);

export const getUploadIdFrom = (modulePrefix, data) =>
	axios.post(createPrefixedImageProcessUrl(modulePrefix), data).then(dataIdPath);

export const uploadCardImageToS3 = (url, file) => axios.put(url, file, createFileUploadAxiosConfig(file));

/**
 *
 * @param file
 * @param sourceUrl
 * @param modulePrefix
 * @returns {Promise<String id>}
 */
export const uploadImageForModule = curry(async (modulePrefix, params) => {
	const { file, url: sourceUrl, searchText: sourceSearchText } = params ?? {};
	if (isNil(file)) {
		return Promise.resolve(null);
	} else {
		try {
			const { token, url } = await getPreValidatedImageUploadData(modulePrefix);
			await uploadCardImageToS3(url, file);
			return await getUploadIdFrom(modulePrefix, {
				token,
				image: {
					sourceUrl,
					sourceSearchText,
				},
			});
		} catch (e) {
			console.log(e);
			return Promise.reject(e);
		}
	}
});
