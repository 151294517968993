import React, { lazy } from 'react';
import { Router } from '@reach/router';

import { INVITATION_PATH, FORGOTTEN_PASSWORD_PATH } from './constants';

const LoginPage = lazy(() => import(/* webpackChunkName: "loginPage" */ '../pages/Login/LoginRegisterPage'));
const InvitationPage = lazy(() =>
	import(/* webpackChunkName: "invitationPage" */ '../pages/Invitation/InvitationPage')
);
const ForgottenPasswordPage = lazy(() =>
	import(/* webpackChunkName: "forgottenPassword" */ '../pages/ForgottenPassword/ForgottenPasswordPage')
);

export const PublicRoutes = () => (
	<Router>
		<LoginPage default path="/" />
		<InvitationPage path={INVITATION_PATH} />
		<ForgottenPasswordPage path={FORGOTTEN_PASSWORD_PATH} />
	</Router>
);
