import React from 'react';
import PropTypes from 'prop-types';
import { Text } from 'rebass';
import Tooltip from '@tippyjs/react';

import { useRedShade } from '../../theme';

export const FormTooltip = ({
	message = 'Pole není správně vyplněno',
	component,
	offset = [50, 48],
	disabled = false,
	position,
}) => {
	const errorRed = useRedShade(600);
	return (
		<Tooltip
			disabled={disabled}
			trigger="mouseenter"
			offset={offset}
			animation="none"
			duration="0"
			theme="light"
			message={message}
			distance="0"
			position={position ?? 'top-end'}
			content={<Text sx={{ color: errorRed }}>{message}</Text>}
			sticky
			arrow
		>
			<div>{component}</div>
		</Tooltip>
	);
};

FormTooltip.propTypes = {
	component: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
	disabled: PropTypes.bool,
	message: PropTypes.string,
	offset: PropTypes.array,
	position: PropTypes.string,
};
