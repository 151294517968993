import { reject, compose, flatten, map, replace, split, o, trim } from 'ramda';
import { defaultToEmptyString, isNilOrEmpty } from 'ramda-extension';

export const removePunctuation = o(reject(isNilOrEmpty), map(replace(/[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/g, '')));

export const replacePunctuation = o(
	// eslint-disable-next-line
	replace(/\b[-.,()&$?#!\[\]{}"']+\B|\B[-.,()&$?#!\[\]{}"']+\b/g, ''),
	defaultToEmptyString
);

const splitBySpace = split(' ');

const splitByWhiteSpaceTag = (text) => text?.split(String.fromCharCode(160));

export const getSingleWordsArray = compose(
	map(trim),
	flatten,
	map(splitBySpace),
	flatten,
	map(splitByWhiteSpaceTag),
	splitByWhiteSpaceTag,
	defaultToEmptyString
);

export const getSingleSanitizedWordsArray = compose(map(trim), map(replacePunctuation), getSingleWordsArray);
