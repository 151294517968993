import React from 'react';
import ReactDOM from 'react-dom';

import Root from './Root';

import './api/config';
import './historyBlocking';
import './i18n';

ReactDOM.render(<Root />, document.getElementById('root'));
