import axios from 'axios';

import { createPageableRequest, dataP } from '../../../utils';
import { LANGUAGES } from '../../../constants/urls';

export const getPageableLanguages = createPageableRequest(LANGUAGES);

export const createLanguage = (language) => axios.post(LANGUAGES, language).then(dataP);

export const deleteLanguage = (language) => axios.delete(`${LANGUAGES}/${language.id}`);

export const editLanguage = (language) => axios.put(`${LANGUAGES}/${language.id}`, { language }).then(dataP);
