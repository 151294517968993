import { REACT_SELECT_CLASSNAME_PREFIX } from '../../components/Forms/ReactSelect';

const primary = {
	bg: 'white',
	borderColor: 'gray.400',
	'& *': {
		borderColor: 'gray.400',
		color: 'gray.700',
	},
	'&:focus-within': {
		borderColor: 'focusBorder',
	},
};

const error = {
	bg: 'white',
	borderColor: 'red.500',
	color: 'red.300',
	'& *': {
		borderColor: 'red.500',
		color: 'red.500',
	},
	'&:focus-within': {
		borderColor: 'red.600',
	},
};

const noBorder = {
	cursor: 'text',
	borderRadius: 3,
	position: 'relative',
	borderColor: 'transparent',
};

const searchWord = {
	borderColor: 'transparent',
	'&:valid': {
		outline: 'none',
		borderColor: 'transparent',
		'& > div > input': {
			borderColor: 'transparent',
		},
	},
	'&:active': {
		outline: 'none',
		borderColor: 'gray.300',
		'& > div > div > div': {
			borderColor: 'gray.300',
			visibility: 'inherit',
		},
	},
	'&:focus-within': {
		outline: 'none',
		borderColor: 'gray.300',
		'& > div > div > div': {
			borderColor: 'gray.300',
			visibility: 'inherit',
		},
	},
	'&:hover': {
		outline: 'none',
		borderColor: 'gray.300',
		'& > div > div > div': {
			borderColor: 'gray.300',
			visibility: 'inherit',
		},
	},
	'& > div > input': {
		borderStyle: 'solid',
		borderWidth: '1px 0px 1px 1px',
		borderColor: 'transparent',
		borderRadius: 3,
	},
	'& > div > div > div': {
		position: 'relative',
		backgroundColor: 'white',
		left: '-11px',
		borderStyle: 'solid',
		borderWidth: '1px',
		borderColor: 'transparent',
		borderRadius: 5,
		visibility: 'hidden',
	},
};

export const textField = {
	primary: {
		...primary,
		'& input': {
			'::placeholder': {
				color: 'gray.500',
			},
			color: 'gray.700',
		},
	},
	withLanguage: {
		...primary,
		borderLeftColor: 'transparent',
		borderRadius: '0 3px 3px 0',
		'& input': {
			'::placeholder': {
				color: 'gray.500',
			},
			color: 'gray.700',
		},
	},
	error: {
		...error,
		'& input': {
			'::placeholder': {
				color: 'red.300',
			},
			color: 'red.500',
		},
	},
	noBorder: {
		...noBorder,
		'& input': {
			'::placeholder': {
				color: 'gray.500',
			},
			color: 'gray.700',
		},
	},
	searchWord,
};

export const select = {
	rightBorder: {
		bg: 'white',
		borderColor: 'gray.300',
		borderRadius: '3px 0 0 3px',
		'& *': {
			borderColor: 'gray.300',
			color: 'gray.300',
		},
		[`& .${REACT_SELECT_CLASSNAME_PREFIX}__control`]: {
			border: 'none',
			borderRight: '1px solid',
			borderColor: 'gray.300',
			borderRadius: '3px 0 0 3px',
			height: '38px',
			[`& .${REACT_SELECT_CLASSNAME_PREFIX}__single-value`]: {
				color: 'gray.700',
			},
			'&:hover, &:focus, &:focus-within, &:active': {
				'& *': {
					color: 'gray.700',
				},
			},
		},
	},
	primary: {
		...primary,
		'& select': {
			color: 'gray.700',
		},
		[`& .${REACT_SELECT_CLASSNAME_PREFIX}__control`]: {
			height: '40px',
			'&:hover,&:focus,&:focus-within,&:active': {
				borderColor: 'gray.700',
			},
			[`& .${REACT_SELECT_CLASSNAME_PREFIX}__indicator`]: {
				color: 'gray.400',
			},
			[`& .${REACT_SELECT_CLASSNAME_PREFIX}__placeholder`]: {
				color: 'gray.400',
			},
			[`& .${REACT_SELECT_CLASSNAME_PREFIX}__multi-value`]: {
				bg: 'gray.200',
				[`& .${REACT_SELECT_CLASSNAME_PREFIX}__multi-value__label`]: {
					color: 'gray.700',
				},
				[`& .${REACT_SELECT_CLASSNAME_PREFIX}__multi-value__remove`]: {
					color: 'gray.400',
				},
			},
		},
	},
	error: {
		...error,
		'& select': {
			color: 'red.300',
		},
		[`& .${REACT_SELECT_CLASSNAME_PREFIX}__control`]: {
			borderColor: '#F56565 !important',
			'&:hover,&:focus,&:focus-within,&:active': {
				borderColor: 'red.600',
			},
			[`& .${REACT_SELECT_CLASSNAME_PREFIX}__placeholder`]: {
				color: 'red.300',
			},
			[`& .${REACT_SELECT_CLASSNAME_PREFIX}__dropdown-indicator`]: {
				'& svg': {
					fill: '#F56565 !important',
				},
			},
			[`& .${REACT_SELECT_CLASSNAME_PREFIX}__multi-value`]: {
				bg: 'red.200',
				[`& .${REACT_SELECT_CLASSNAME_PREFIX}__multi-value__label`]: {
					color: 'red.600',
				},
				[`& .${REACT_SELECT_CLASSNAME_PREFIX}__multi-value__remove`]: {
					color: 'red.500',
				},
			},
		},
	},
};
