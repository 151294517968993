import React from 'react';
import PropTypes from 'prop-types';
import { isNotNil } from 'ramda-extension';
import { Box, Flex, Text } from 'rebass';

import { MdHelp } from 'react-icons/md';
import { BackButton } from '../Button';
import { useInteractiveHeadingValue } from '../../features/interactiveHeading';
import { Tooltip } from '../Tooltip';
import { Icon } from '../Icon';

const editorHeadingTextColor = 'gray.700';

export const EditorHeading = ({ title, name, lastUpdate, showHelp, hideBackButton }) => {
	const headingInteractiveValue = useInteractiveHeadingValue();
	return (
		<Box px={3} pb={3} pt={3} bg="background" data-cy="EditorHeading">
			<Flex justifyContent="space-between" alignItems="center">
				<Flex alignItems="center">
					<BackButton hideBackButton={hideBackButton} />
					<Text fontSize={3} mr={2} color={editorHeadingTextColor} data-cy="EditorHeading.title">
						{title}
					</Text>
					{isNotNil(headingInteractiveValue || name) && (
						<Text fontSize={3} color={editorHeadingTextColor} fontWeight={700} data-cy="EditorHeading.name">
							{`„${headingInteractiveValue || name}“`}
						</Text>
					)}
				</Flex>
				{isNotNil(lastUpdate) && (
					<Flex>
						<Text fontSize={1} mr={2} color={editorHeadingTextColor}>
							Naposledy upraveno:
						</Text>
						<Text fontSize={1} fontWeight={700} color={editorHeadingTextColor}>
							{lastUpdate}
						</Text>
					</Flex>
				)}
				{isNotNil(showHelp) && (
					<Flex>
						<Icon
							fontSize={40}
							sx={{ position: 'absolute', top: '90px', right: '10px', cursor: 'pointer' }}
							color="gray.400"
							icon={
								<Tooltip label="Otevřít nápovědu" offsetBottom={0}>
									<MdHelp />
								</Tooltip>
							}
							onClick={showHelp}
						/>
					</Flex>
				)}
			</Flex>
		</Box>
	);
};

EditorHeading.propTypes = {
	hideBackButton: PropTypes.bool,
	lastUpdate: PropTypes.string,
	name: PropTypes.string,
	showHelp: PropTypes.bool,
	title: PropTypes.string.isRequired,
};
