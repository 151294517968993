import PropTypes from 'prop-types';

export const rebassPropTypes = {
	sx: PropTypes.object,
	variant: PropTypes.oneOf(['secondary', 'primary', 'error', 'outline', 'disabled', 'secondaryWithBorder']),
	/**
	 * left and right margin
	 */
	mx: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	/**
	 * margin right
	 */
	mr: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	/**
	 * margin top
	 */
	mt: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	/**
	 * margin bottom
	 */
	mb: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	/**
	 * margin left
	 */
	ml: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	/**
	 * margin
	 */
	m: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	/**
	 * left and right padding
	 */
	px: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	/**
	 * padding
	 */
	p: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	/**
	 * padding left
	 */
	pl: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	/**
	 * padding right
	 */
	pr: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	/**
	 * padding top
	 */
	pt: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	/**
	 * padding bottom
	 */
	pb: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};
