import { useCallback, useRef } from 'react';
import { without, identity } from 'ramda';
import axios from 'axios';
import { BehaviorSubject } from 'rxjs';

import { removePunctuation } from '../utils/wordSanitization';
import { DICTIONARY_SERVICE_URL, IGNORED_WORDS_MAP_KEY } from '../constants';
import { getIsDev, isNotNilOrEmpty, safeJSONParse } from '../../../utils';

export const useDictionaryForLanguage = (languageCode) => {
	const behaviourSubjectRef = useRef(new BehaviorSubject({}));
	const spellingData = useRef({});
	const setSpellingData = (transformer = identity) => {
		const updatedSpellingData = transformer(spellingData.current);
		spellingData.current = updatedSpellingData;
		behaviourSubjectRef.current.next(updatedSpellingData);
	};
	const requestSpellingCheck = useCallback(
		(words) => {
			if (isNotNilOrEmpty(words)) {
				const currentIgnoredWordsForLanguage =
					(safeJSONParse(localStorage.getItem(IGNORED_WORDS_MAP_KEY)) ?? {})[languageCode] ?? [];
				axios
					.get(`${getIsDev() ? 'http://localhost:5000' : DICTIONARY_SERVICE_URL}/spelling`, {
						params: {
							words: removePunctuation(without(currentIgnoredWordsForLanguage, words)),
							language: languageCode,
						},
					})
					.then(({ data }) => {
						spellingData.current = data;
						behaviourSubjectRef.current.next(data);
					})
					.catch((e) => {
						console.log(e);
						spellingData.current = {};
						behaviourSubjectRef.current.next({});
					});
			}
		},
		[languageCode]
	);
	return [behaviourSubjectRef, requestSpellingCheck, setSpellingData];
};
