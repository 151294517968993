import { noop } from 'ramda-extension';
import { globalHistory } from '@reach/router';

let navigationBlocked = false;
let navigationBlockCallback = noop;

export const blockNavigation = (cb = noop) => {
	navigationBlockCallback = cb;
	navigationBlocked = true;
};

export const enableNavigation = () => {
	navigationBlockCallback = noop;
	navigationBlocked = false;
};

const originalNavigate = globalHistory.navigate;
globalHistory.navigate = (...props) => {
	if (navigationBlocked) {
		return navigationBlockCallback(...props);
	} else {
		return originalNavigate(...props);
	}
};
