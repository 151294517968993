import { useCallback } from 'react';
import { useParams } from '@reach/router';

import { LAST_EDITED_ID } from './constants';
import { useLocationState, useNavigationWithState } from '../../features/locationState/locationContext';
import { FROM_DECK, FROM_TEXTBOOK } from '../../features/locationState/constants';

export const useNavigateBackWithCurrentId = () => {
	const { id } = useParams();
	const navigate = useNavigationWithState();
	const locationState = useLocationState() ?? {};

	return useCallback(() => {
		if (locationState[FROM_DECK] || locationState[FROM_TEXTBOOK]) {
			navigate(-1, { ...locationState, [LAST_EDITED_ID]: id });
		} else {
			navigate(-1, { [LAST_EDITED_ID]: id });
		}
	}, [navigate, locationState, id]);
};

export const useNavigateBackCurrentId = () => {
	const currentState = useLocationState();
	return currentState[LAST_EDITED_ID];
};
