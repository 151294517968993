import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import { LocationStateContext } from './locationContext';

export const LocationStateProvider = ({ children }) => {
	const [locationState, setLocationState] = useState({});

	const value = useMemo(() => ({ locationState, setLocationState }), [locationState]);
	return <LocationStateContext.Provider value={value}>{children}</LocationStateContext.Provider>;
};

LocationStateProvider.propTypes = {
	children: PropTypes.node,
};
