import { useCallback, useEffect } from 'react';
import { __, any, compose, filter, includes, map, o, prop, values } from 'ramda';
import { useLocation, useNavigate } from '@reach/router';

import { useTranslation } from 'react-i18next';
import { useUserPrivileges } from '../../routes';
import { privilegesAction } from '../../constants';
import { rafiModuleRouteMap, rafiRouteModuleMap } from '../../routes/hooks';

export const useScrollToTopOnLocationChange = () => {
	const { pathname } = useLocation();
	useEffect(() => {
		window.scrollTo(0, 0);
	}, [pathname]);
};

export const useIsCurrent = (url, isDefault) => {
	const { pathname } = useLocation();
	if (url !== '/') {
		if (isDefault) {
			return pathname === '/' || includes(url, pathname);
		}
		return includes(url, pathname);
	}
};

export const useEditorPath = () => {
	const { t } = useTranslation('common');
	const { pathname } = useLocation();
	const navigate = useNavigate();

	const getCurrentPathConfig = useCallback(() => {
		if (includes('cards', pathname)) {
			return {
				editor: '/cards/editor',
				label: t('common.addButtonsLabels.newCard'),
			};
		}
		if (includes('decks', pathname)) {
			return {
				editor: '/decks/editor',
				label: t('common.addButtonsLabels.newDeck'),
			};
		}
		if (includes('textbooks', pathname)) {
			return {
				editor: '/textbooks/editor',
				label: t('common.addButtonsLabels.newTextbook'),
			};
		}
		if (includes('users', pathname)) {
			return {
				editor: '/users/editor',
				label: t('common.addButtonsLabels.newUser'),
			};
		}
	}, [pathname, t]);

	const enterListener = useCallback(
		(e) => {
			if (e.code === 'Enter' && (e.ctrlKey || e.metaKey)) {
				navigate(getCurrentPathConfig().editor);
			}
		},
		[getCurrentPathConfig, navigate]
	);

	useEffect(() => {
		document.addEventListener('keydown', enterListener);
		return () => document.removeEventListener('keydown', enterListener);
	}, [enterListener]);

	return getCurrentPathConfig();
};

export const useHasAnyVisibleSubItems = (toUrl) => {
	const privileges = useUserPrivileges();
	return o(
		any((moduleName) => includes(`${privilegesAction.VIEW}_${moduleName}`, privileges)),
		compose(map(prop(__, rafiRouteModuleMap)), filter(includes(toUrl)), values)
	)(rafiModuleRouteMap);
};
