import { curry, propEq, find, sortBy, o } from 'ramda';

import { sortByLabel } from './ramda';
import { ENGLISH_LANGUAGE_CODE } from '../constants';
import { languageLabels } from '../features/codelist/constants';

export const findByValue = curry((codelist, value) => find(propEq('value', value), codelist));
export const findByLabel = curry((codelist, value) => find(propEq('label', value), codelist));

const sortByEnLanguageCode = sortBy(({ label }) =>
	label === languageLabels[ENGLISH_LANGUAGE_CODE] || label === ENGLISH_LANGUAGE_CODE ? -1 : 1
);
export const sortLanguageCodelistData = o(sortByEnLanguageCode, sortByLabel);
