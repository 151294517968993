import { isNilOrEmpty } from 'ramda-extension';
import axios from 'axios';

import { CARDS } from '../../constants/urls';
import { uploadImageForModule } from '../images';
import { createPageableRequest, dataDataPath, dataP, isNotNilOrEmpty } from '../../utils';

export const getSingleCard = (/* api cache key */ _, id) => axios.get(`${CARDS}/${id}`).then(dataP);

export const getAllCards = (name, language, languageToSearchBy, limit = 10) =>
	axios
		.get(CARDS, {
			params: {
				limit,
				...(name ? { term: name } : {}),
				...(language ? { language } : {}),
				...(languageToSearchBy ? { baseLanguage: languageToSearchBy } : {}),
			},
		})
		.then(dataDataPath);

export const getAllDeckCards = (/* api cache key */ _, cardIds, { term, language, limit, baseLanguage }) => {
	if (isNilOrEmpty(cardIds)) {
		return Promise.resolve({ data: [] });
	}
	return axios
		.get(CARDS, {
			params: {
				ids: cardIds,
				...(term ? { term } : {}),
				...(language ? { language } : {}),
				...(limit ? { limit } : {}),
				...(baseLanguage ? { baseLanguage } : {}),
				sortBy: isNotNilOrEmpty(cardIds) ? 'ids' : '',
			},
		})
		.then(({ data }) => ({
			data: data.data,
			totalCount: data.totalCount,
		}));
};

export const getPageableCards = createPageableRequest(CARDS, 'cardId');

export const createCard = (card) => axios.post(CARDS, card).then(dataP);

export const deleteCard = (id) => axios.delete(`${CARDS}/${id}`).then(dataP);

export const deleteCards = (cardIds) => axios.delete(CARDS, { params: { ids: cardIds } }).then(dataP);

export const bulkAddCardsToDecks = (data) => axios.post(`${CARDS}/decks`, data).then(dataP);

export const editCard = (card, id) => axios.put(`${CARDS}/${id}`, card).then(dataP);

export const uploadCardImage = uploadImageForModule(CARDS);
