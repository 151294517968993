import axios from 'axios';
import { navigate } from '@reach/router';

import { DO_NOT_INCLUDE_AUTH_HEADER, IMAGE_URL_PARTS } from './images';
import { PERSIST_AUTH_KEY } from '../routes/context/constants';

export const setAxiosAuthorizationResponseInterceptor = (setCredentials) => {
	axios.interceptors.response.use(
		(res) => res,
		(err) => {
			if (err?.response?.status && err.response.status === 401) {
				localStorage.removeItem(PERSIST_AUTH_KEY);
				setCredentials(null);
				navigate('/');
			}
			throw err;
		}
	);
};

export const setAxiosAuthorizationRequestInterceptor = (data) => {
	axios.interceptors.request.use((req) => {
		if (req.headers[DO_NOT_INCLUDE_AUTH_HEADER] === 'true') {
			delete req.headers[DO_NOT_INCLUDE_AUTH_HEADER];
		} else if (data?.token && !IMAGE_URL_PARTS.some((part) => req.url.includes(part))) {
			req.headers.authorization = `Bearer ${data.token}`;
		}
		return req;
	});
};

export const BASE_URL =
	process.env.REACT_APP_STAGE === 'production' ? window.__RUNTIME_CONFIG__.REACT_APP_API_URL : 'http://localhost:3000';

export const API_BASE_URL = BASE_URL + '/api/v1';

axios.defaults.baseURL = API_BASE_URL;
