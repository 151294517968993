import React, { useCallback, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import { mergeRight } from 'ramda';

import { CachedSelectContext } from './context';

export const SelectCacheProvider = ({ children }) => {
	const selectCache = useRef({});
	const getCachedValue = useCallback((cacheKey) => selectCache?.current?.[cacheKey], [selectCache]);
	const setCachedValue = useCallback(
		(key, value) => (selectCache.current = mergeRight(selectCache?.current ?? {}, { [key]: value })),
		[selectCache]
	);
	const value = useMemo(() => ({ get: getCachedValue, set: setCachedValue }), [getCachedValue, setCachedValue]);
	return <CachedSelectContext.Provider value={value}>{children}</CachedSelectContext.Provider>;
};

SelectCacheProvider.propTypes = {
	children: PropTypes.any,
};
