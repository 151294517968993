import React from 'react';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';
import AsyncSelect from 'react-select/async';
import { Box } from 'rebass';

import { FormErrorText } from './FormErrorText';
import { REACT_SELECT_CLASSNAME_PREFIX } from './ReactSelect';
import { emptyArray } from '../../constants';

export const TagsInput = ({
	async,
	options = emptyArray,
	loadOptions,
	emptyOptionsMessage = 'Žádné možnosti',
	placeholder,
	variant,
	errorMessage,
	...other
}) => {
	const Component = async ? AsyncSelect : ReactSelect;
	return (
		<FormErrorText errorMessage={errorMessage}>
			<Box
				sx={{
					height: '40px',
					variant: `forms.fields.select.${variant}`,
				}}
			>
				<Component
					isMulti
					loadOptions={loadOptions}
					noOptionsMessage={() => emptyOptionsMessage}
					options={options}
					placeholder={placeholder}
					classNamePrefix={REACT_SELECT_CLASSNAME_PREFIX}
					{...other}
				/>
			</Box>
		</FormErrorText>
	);
};

TagsInput.propTypes = {
	async: PropTypes.bool,
	emptyOptionsMessage: PropTypes.string,
	errorMessage: PropTypes.string,
	/**
	 * Used only when async is truthy
	 */
	loadOptions: PropTypes.func,
	/**
	 * [{ value: 'val', label: 'lab' }]
	 */
	options: PropTypes.array,
	placeholder: PropTypes.string,
	variant: PropTypes.string,
};
