import React, { memo, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { TableSearchContext } from './tableSearchContext';

export const TableSearchProvider = memo(({ children }) => {
	const [search, setSearch] = useState({});
	const searchString = JSON.stringify(search);
	const value = useMemo(() => ({ search: JSON.parse(searchString), setSearch }), [searchString, setSearch]);
	return <TableSearchContext.Provider value={value}>{children}</TableSearchContext.Provider>;
});

TableSearchProvider.displayName = 'memo(TableSearchProvider)';

TableSearchProvider.propTypes = {
	children: PropTypes.any,
};
