import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import { Text, Flex, Box } from 'rebass';

import { useConfirmModalStyles } from './hooks';
import { Button } from '../Button';

export const ConfirmModal = ({
	hideModal,
	closeLabel = 'Zavřít',
	confirmLabel,
	confirmOnClick,
	heading,
	hideCloseButton = false,
}) => {
	const modalStyles = useConfirmModalStyles();

	const enterListener = useCallback(
		(e) => {
			if (e.code === 'Enter') {
				confirmOnClick();
			}
		},
		[confirmOnClick]
	);

	useEffect(() => {
		document.addEventListener('keydown', enterListener);
		return () => document.removeEventListener('keydown', enterListener);
	}, [enterListener]);
	return (
		<ReactModal isOpen style={modalStyles} onRequestClose={hideModal} ariaHideApp={false}>
			<Box p={3}>
				<Text mb={4}>{heading}</Text>
				<Flex justifyContent="space-between" alignItems="center">
					{!hideCloseButton && (
						<Button fontSize={14} label={closeLabel} variant="secondary" onClick={hideModal} mr={2} />
					)}
					{confirmLabel && (
						<Button
							fontSize={14}
							fontWeight={700}
							sx={{ cursor: 'pointer' }}
							label={confirmLabel}
							onClick={confirmOnClick}
						/>
					)}
				</Flex>
			</Box>
		</ReactModal>
	);
};

ConfirmModal.propTypes = {
	closeLabel: PropTypes.string,
	confirmLabel: PropTypes.string,
	confirmOnClick: PropTypes.func,
	heading: PropTypes.string,
	hideCloseButton: PropTypes.bool,
	hideModal: PropTypes.func,
};
