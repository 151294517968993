import { useCallback, useEffect, useMemo, useState } from 'react';
import { head, filter, o, compose, identity, map } from 'ramda';
import { flipIncludes, isNotEmpty, notFlipInclude } from 'ramda-extension';

import { useCodelist } from '../../../features/codelist';
import { LANGUAGES_CODELIST_NAME } from '../../../constants';
import { isNotNilOrEmpty, pluckCode, sortLanguageCodelistData, valueP } from '../../../utils';

const useNextMultiLanguageSelectProps = (nextLanguageCode, setNextLanguageCode, languages) =>
	useMemo(
		() => ({
			value: nextLanguageCode,
			onChange: setNextLanguageCode,
			options: languages,
		}),
		[nextLanguageCode, languages, setNextLanguageCode]
	);

const toString = (value) => value?.toString();
const mapToString = map(toString);

export const useMultiLanguageField = (fieldArrayFuncs, allowedLanguages) => {
	const { fields, append, remove } = fieldArrayFuncs;

	const { data: supportedLanguages } = useCodelist(LANGUAGES_CODELIST_NAME);

	const languageOptions = useMemo(() => {
		const filterAllowed = isNotNilOrEmpty(allowedLanguages)
			? filter(o(flipIncludes(allowedLanguages), valueP))
			: identity;

		const filterOptions = compose(
			filter(compose(notFlipInclude(mapToString(pluckCode(fields))), toString, valueP)),
			filterAllowed
		);
		return o(sortLanguageCodelistData, filterOptions)(supportedLanguages);
	}, [allowedLanguages, fields, supportedLanguages]);

	const firstAvailableLanguageOption = useMemo(() => head(languageOptions), [languageOptions]);

	const [nextLanguageValue, setNextLanguageValue] = useState('');
	const [nextLanguageCode, setNextLanguageCode] = useState(firstAvailableLanguageOption);

	useEffect(() => {
		setNextLanguageCode(firstAvailableLanguageOption);
	}, [firstAvailableLanguageOption]);

	const clearNextLanguage = useCallback(() => {
		setNextLanguageCode(head(languageOptions));
	}, [languageOptions]);

	const appendLanguage = useCallback(() => {
		const { label, value } = nextLanguageCode;
		append({ label, code: value, value: nextLanguageValue });
		clearNextLanguage();
	}, [append, clearNextLanguage, nextLanguageCode, nextLanguageValue]);

	const removeLanguage = useCallback((languageIndex) => remove(languageIndex), [remove]);

	const nextMultiLanguageSelectProps = useNextMultiLanguageSelectProps(
		nextLanguageCode,
		setNextLanguageCode,
		languageOptions
	);

	return {
		fields,
		nextLanguageValue,
		setNextLanguageValue,
		appendLanguage,
		removeLanguage,
		nextMultiLanguageSelectProps,
		hasAnyLanguageCodes: isNotEmpty(languageOptions),
	};
};
