import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty, isNil } from 'ramda';
import { isNotNil } from 'ramda-extension';
import { useFormContext } from 'react-hook-form';
import get from 'lodash.get';
import { MdErrorOutline } from 'react-icons/md';

import { Icon } from '../../components/Icon';
import { emptyObject } from '../../constants';
import { FormLabel, TextField } from '../../components/Forms';
import { FormTooltip } from '../../components/Forms/FormTooltip';
import { useSetErrorForSectionEffect } from '../../features/formSectionError';

export const UncontrolledField = ({
	bottom = 30,
	label,
	type,
	component: Component = TextField,
	offset,
	right = 12,
	rules = emptyObject,
	name,
	variant,
	labelProps,
	...other
}) => {
	const { register, errors } = useFormContext();
	const fieldError = get(errors, name);
	// eslint-disable-next-line
	const star = rules?.required ? "'*'" : "''";
	const isLabelHidden = !!(isEmpty(label) || isNil(label));
	const hasErrors = isNotNil(fieldError);
	useSetErrorForSectionEffect(hasErrors, name);
	return (
		<>
			{isLabelHidden ? null : (
				<FormLabel
					label={label}
					htmlFor={name}
					{...labelProps}
					sx={{
						'&:after': {
							color: 'red.600',
							content: `${star}`,
						},
					}}
				/>
			)}
			<div>
				<Component
					autocomplete="off"
					name={name}
					variant={isNotNil(fieldError) ? 'error' : variant}
					register={register(rules)}
					type={type === 'password' ? type : fieldError ? null : type}
					rules={hasErrors ? null : rules}
					{...other}
				/>
				<div style={{ float: 'right', zIndex: '999', height: 0 }} data-cy={`${name}.error`}>
					<FormTooltip
						message={fieldError?.message || 'Pole není správně vyplněno'}
						offset={offset}
						component={
							hasErrors ? (
								<Icon
									sx={{
										position: 'relative',
										color: 'red.600',
										float: 'right',
										bottom: `${bottom}px`,
										right: `${right}px`,
									}}
									icon={<MdErrorOutline />}
								/>
							) : null
						}
					/>
				</div>
			</div>
		</>
	);
};

UncontrolledField.propTypes = {
	bottom: PropTypes.number,
	component: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
	label: PropTypes.string,
	labelProps: PropTypes.object,
	name: PropTypes.string,
	offset: PropTypes.array,
	right: PropTypes.number,
	rules: PropTypes.object,
	type: PropTypes.string,
	variant: PropTypes.string,
};
