import { o, toLower } from 'ramda';
import { isString } from 'ramda-extension';

export const deAccentString = (string) =>
	isString(string) ? string.normalize('NFD').replace(/[\u0300-\u036f]/g, '') : '';

export const lowerAndDeAccentString = o(toLower, deAccentString);

export const normalize = (value = '') =>
	value
		.normalize('NFD')
		.replace(/[\u0300-\u036f]/g, '')
		.replace(/\u00a0/gm, ' ');
