import { useMemo } from 'react';

import { useBorderColor } from '../../theme';

export const useConfirmModalStyles = () => {
	const borderColor = useBorderColor();
	return useMemo(
		() => ({
			content: {
				top: '50%',
				left: '50%',
				right: 'auto',
				bottom: 'auto',
				marginRight: '-50%',
				transform: 'translate(-50%, -50%)',
				borderColor,
			},
			overlay: {
				backgroundColor: 'rgba(0,0,0,0.502)',
			},
		}),
		[borderColor]
	);
};
