import React from 'react';
import { MdRefresh } from 'react-icons/md';
import { Flex } from 'rebass';

import { Icon } from '../Icon';
import { usePrimaryColor } from '../../theme';

export const RefreshButton = (props) => {
	const primaryColor = usePrimaryColor();
	return (
		<Flex
			justifyContent="center"
			alignItems="center"
			sx={{ border: '1px solid', height: '40px', borderColor: 'gray.300', borderRadius: '3px' }}
			{...props}
		>
			<Icon icon={<MdRefresh />} color={primaryColor} height={30} width={30} />
		</Flex>
	);
};
