import React, { Suspense } from 'react';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'theme-ui';
import { IconContext } from 'react-icons';
import { ModalProvider } from 'react-modal-hook';
import moment from 'moment';
import 'moment/locale/cs';

import App from './containers/App/App';
import { swissTheme } from './theme/theme';
import { configureStore } from './redux/store';
import { GlobalStyles } from './containers/App/GlobalStyles';
import { AuthProvider } from './routes/context/AuthProvider';
import { SelectCacheProvider } from './features/selectCache';
import { LocationStateProvider } from './features/locationState/LocationStateProvider';

moment.locale('cs');
const store = configureStore({});

const Root = () => (
	<Provider store={store}>
		<ThemeProvider theme={swissTheme}>
			<LocationStateProvider>
				<IconContext.Provider value={{}}>
					<ModalProvider>
						<Suspense fallback={<div>Loading...</div>}>
							<GlobalStyles />
							<AuthProvider>
								<SelectCacheProvider>
									<App />
								</SelectCacheProvider>
							</AuthProvider>
						</Suspense>
					</ModalProvider>
				</IconContext.Provider>
			</LocationStateProvider>
		</ThemeProvider>
	</Provider>
);

export default Root;
