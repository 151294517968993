import React from 'react';
import PropTypes from 'prop-types';
import { Box, Flex } from 'rebass';
import { MdSearch } from 'react-icons/md';

import { REACT_SELECT_CLASSNAME_PREFIX } from './constants';
import { Icon } from '../../Icon';

export const SearchValueContainer = ({ children }) => (
	<Box
		sx={{
			[`& .${REACT_SELECT_CLASSNAME_PREFIX}__placeholder`]: {
				left: '36px',
			},
			[`& .${REACT_SELECT_CLASSNAME_PREFIX}__single-value`]: {
				left: '36px',
			},
		}}
	>
		<Flex>
			<Icon ml={2} color="gray.400" icon={<MdSearch />} />
			{children}
		</Flex>
	</Box>
);

SearchValueContainer.propTypes = {
	children: PropTypes.node,
};
