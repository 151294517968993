import ReactSelect from 'react-select/async';
import ReactSelectAsync from 'react-select/async';
import ReactSelectCreatable from 'react-select/creatable';
import ReactSelectAsyncCreatable from 'react-select/async-creatable';
import ButtonBase from '@material-ui/core/ButtonBase';
import ReactModal from 'react-modal';

ButtonBase.defaultProps = {
	focusRipple: false,
	disableRipple: true,
	disableTouchRipple: true,
	disableFocusRipple: true,
};

const placeholder = 'Vybrat';
const renderLoadingMessage = () => 'Načítání...';
const renderNoOptionsMessage = () => 'Žádné další možnosti';
const renderCreateMessage = (inputValue) => `Vytvořit "${inputValue}"`;

const reactSelectCommonDefaultProps = {
	placeholder,
	loadingMessage: renderLoadingMessage,
	noOptionsMessage: renderNoOptionsMessage,
};

const reactCreateableSelectCommonDefaultProps = {
	...reactSelectCommonDefaultProps,
	formatCreateLabel: renderCreateMessage,
};

ReactModal.defaultStyles.overlay.backgroundColor = 'rgba(0,0,0,0.502)';

ReactSelect.defaultProps = reactSelectCommonDefaultProps;

ReactSelectAsync.defaultProps = reactSelectCommonDefaultProps;

ReactSelectCreatable.defaultProps = reactCreateableSelectCommonDefaultProps;

ReactSelectAsyncCreatable.defaultProps = reactCreateableSelectCommonDefaultProps;
