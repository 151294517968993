import React, { lazy } from 'react';
import { Router } from '@reach/router';

import { useNavigationForFirstVisibleModule } from './hooks';
import { FORGOTTEN_PASSWORD_PATH, INVITATION_PATH } from './constants';
import { TableSearchProvider } from '../components/Table/SearchContext';

const CardEditor = lazy(() =>
	import(/* webpackChunkName: "cardEditorPage" */ '../pages/CardEditorPage/CardEditorPage')
);
const DictionaryPage = lazy(() => import(/* webpackChunkName: "wordsPage" */ '../pages/Dictionary/DictionaryPage'));
const CardsPage = lazy(() => import(/* webpackChunkName: "cardsPage" */ '../pages/Cards/CardsPage'));
const UsersPage = lazy(() => import(/* webpackChunkName: "usersPage" */ '../pages/Users/UsersPage'));
const UserEditorPage = lazy(() =>
	import(/* webpackChunkName: "userEditorPage" */ '../pages/UserEditor/UserEditorPage')
);
const ProfilePage = lazy(() => import(/* webpackChunkName: "AppUsersPage" */ '../pages/ProfilePage/ProfilePage'));
const TextbooksPage = lazy(() => import(/* webpackChunkName: "textBooksPage" */ '../pages/TextBooks/TextbooksPage'));
// const DecksPage = lazy(() => import(/* webpackChunkName: "DecksPage" */ '../pages/DecksPage/DecksPage'));

// const DeckEditorPage = lazy(() =>
// 	import(/* webpackChunkName: "DeckEditorPage" */ '../pages/DeckEditorPage/DeckEditorPage')
// );

const DifficultiesTableEditorPage = lazy(() =>
	import(
		/* webpackChunkName: "difficultiesTableEditorPage" */ '../pages/DifficultiesTableEditor/DifficultiesTableEditorPage'
	)
);
const RolesTableEditorPage = lazy(() =>
	import(/* webpackChunkName: "rolesTableEditorPage" */ '../pages/RolesTableEditor/RolesTableEditorPage')
);

const LanguagesTableEditorPage = lazy(() =>
	import(/* webpackChunkName: "languagesTableEditorPage" */ '../pages/LanguagesTableEditor/LanguagesTableEditorPage')
);
const PublishersTableEditorPage = lazy(() =>
	import(/* webpackChunkName: "publishersTableEditorPage" */ '../pages/PublishersTableEditor/PublishersTableEditorPage')
);
const FeaturedTextbooksPage = lazy(() =>
	import(/* webpackChunkName: "featuredDecks" */ '../pages/FeaturedTextbooks/FeaturedTextbooksPage')
);
const MultiFormPage = lazy(() =>
	import(/* webpackChunkName: "multiFormPage" */ '../pages/TextbookCreator/TextbookCreator')
);
const InvitationPage = lazy(() =>
	import(/* webpackChunkName: "invitationPage" */ '../pages/Invitation/InvitationPage')
);
const RolePrivilegesTableEditorPage = lazy(() =>
	import(
		/* webpackChunkName: "rolePrivilegesTableEditor" */ '../pages/RolePrivilegesTableEditor/RolePrivilegesTableEditorPage'
	)
);
const ForgottenPasswordPage = lazy(() =>
	import(/* webpackChunkName: "forgottenPassword" */ '../pages/ForgottenPassword/ForgottenPasswordPage')
);

export const ProtectedRoutes = () => {
	useNavigationForFirstVisibleModule();
	return (
		<Router>
			<TableSearchProvider default path="/">
				<TextbooksPage path="/" />
				<MultiFormPage path="/editor" />
			</TableSearchProvider>
			<TableSearchProvider path="/textbooks">
				<TextbooksPage path="/" />
				<MultiFormPage path="/editor/:id" />
				<MultiFormPage path="/editor" create />
			</TableSearchProvider>
			<DictionaryPage path="/dictionary" />
			<TableSearchProvider path="/cards">
				<CardEditor path="/editor/:id" />
				<CardEditor path="/editor" create />
				<CardsPage path="/" />
			</TableSearchProvider>
			<TableSearchProvider path="/users">
				<UsersPage path="/" />
				<UserEditorPage path="/editor/:id" />
				<UserEditorPage path="/editor" create />
			</TableSearchProvider>
			{/*<TableSearchProvider path="/decks">*/}
			{/*	<DecksPage path="/" />*/}
			{/*	<DeckEditorPage path="/editor/:id" />*/}
			{/*	<DeckEditorPage path="/editor" create />*/}
			{/*</TableSearchProvider>*/}
			<ProfilePage path="/profile" />
			<RolesTableEditorPage path="/settings/roles" />
			<RolePrivilegesTableEditorPage path="/settings/roles/privileges/:id" />
			<LanguagesTableEditorPage path="/settings/languages" />
			<DifficultiesTableEditorPage path="/settings/difficulties" />
			<PublishersTableEditorPage path="/settings/publishers" />
			<TableSearchProvider path="/settings/featured-textbook">
				<FeaturedTextbooksPage path="/" />
			</TableSearchProvider>
			<InvitationPage path={INVITATION_PATH} />
			<ForgottenPasswordPage path={FORGOTTEN_PASSWORD_PATH} />
		</Router>
	);
};
