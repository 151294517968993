import { prop, path, pluck, o, propEq, not, flip, concat, sortBy, equals, length, includes, map } from 'ramda';
import { isNilOrEmpty } from 'ramda-extension';

export const payloadP = prop('payload');

export const valueP = prop('value');
export const labelP = prop('label');

export const labelPEq = propEq('label');

export const pluckValue = pluck('value');
export const pluckLabel = pluck('label');

export const dataP = prop('data');

export const idP = prop('id');

export const dataDataPath = path(['data', 'data']);

export const flipConcat = flip(concat);

export const dataIdPath = path(['data', 'id']);
export const pluckCode = pluck('code');

export const isNotNilOrEmpty = o(not, isNilOrEmpty);

export const notIncludes = o(o(not), includes);

export const sortByLabel = sortBy(prop('label'));

export const lengthEquals = (first, second) => equals(length(first), length(second));

export const mapToString = map((v) => v?.toString());
