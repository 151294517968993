import axios from 'axios';

import { createPageableRequest, dataP } from '../../../utils';
import { PUBLISHERS } from '../../../constants/urls';

export const getPageablePublishers = createPageableRequest(PUBLISHERS);

export const createPublisher = (publisher) => axios.post(PUBLISHERS, publisher).then(dataP);

export const deletePublisher = (publisher) => axios.delete(`${PUBLISHERS}/${publisher.id}`);

export const editPublisher = (publisher) => axios.put(`${PUBLISHERS}/${publisher.id}`, { publisher }).then(dataP);
