import React from 'react';
import PropTypes from 'prop-types';
import { Flex } from 'rebass';
import ReactLoaderSpinner from 'react-loader-spinner';

import { usePrimaryColor } from '../../theme';

export const Loader = ({ size = 30, ...other }) => {
	const primaryColor = usePrimaryColor();
	return (
		<Flex justifyContent="center" alignItems="center" minWidth="70px" width="100%" height="40px" {...other}>
			<ReactLoaderSpinner type="TailSpin" color={primaryColor} height={size} width={size} />
		</Flex>
	);
};

Loader.propTypes = {
	size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
