import { useWith, mergeDeepRight, identity } from 'ramda';
import { handleAction } from 'redux-actions';

import { appContextActionTypes } from './constants';
import { payloadP } from '../../utils';
import { emptyObject } from '../../constants';

export default handleAction(
	appContextActionTypes.MERGE_CONTEXT_DEEP,
	useWith(mergeDeepRight, [identity, payloadP]),
	emptyObject
);
