import React, { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { assoc, dissoc } from 'ramda';
import { noop } from 'ramda-extension';
import { useModal } from 'react-modal-hook';
import { useLocation, useNavigate } from '@reach/router';

import { useTranslation } from 'react-i18next';
import { emptyObject } from '../../constants';
import { FormAlert } from '../../components/Forms';
import { useScrollToTop } from '../../features/scrollTo';
import { ConfirmModal } from '../../components/Modal/ConfirmModal';
import { blockNavigation, enableNavigation } from '../../historyBlocking';
import { LocationStateContext } from '../../features/locationState/locationContext';

const AUTOCLOSE_DURATION = 1000 * 5;

export const useFormState = ({
	tabIndex,
	onSubmit,
	hideErrorAlert,
	hideSuccessAlert,
	reset = noop,
	submitMessages = emptyObject,
	setIsDirty = noop,
}) => {
	const [isSending, setIsSending] = useState(false);
	const [tabVisibilityState, setTabVisibilityState] = useState({});
	const [tabSubmitState, setTabSubmitState] = useState({});
	const { locationState, setLocationState } = useContext(LocationStateContext);
	const scrollToTop = useScrollToTop();

	const fresh = locationState?.fresh;
	const [isFresh, setIsFresh] = useState(fresh);

	const { errorTitle, errorMessage, successTitle, successMessage, createdMessage } = submitMessages;

	const showMessage = useCallback(() => setTabVisibilityState(assoc(tabIndex, true)), [
		setTabVisibilityState,
		tabIndex,
	]);
	const hideMessage = useCallback(() => {
		setTabVisibilityState(assoc(tabIndex, false));
		setIsFresh(false);
	}, [setTabVisibilityState, tabIndex]);

	const setAutoHide = useCallback(() => {
		setTimeout(() => {
			setTabSubmitState(dissoc(tabIndex));
			hideMessage();
		}, AUTOCLOSE_DURATION);
	}, [hideMessage, tabIndex]);

	const handleSetSuccessMessages = useCallback(
		(message) => {
			setTabSubmitState(
				assoc(tabIndex, {
					variant: 'success',
					title: successTitle,
					message: message ?? successMessage,
				})
			);
			setAutoHide();
		},
		[setAutoHide, successMessage, successTitle, tabIndex]
	);

	const handleSetErrorMessages = useCallback(
		(formErrorMessage) => {
			setTabSubmitState(
				assoc(tabIndex, {
					variant: 'error',
					title: errorTitle,
					message: formErrorMessage ?? errorMessage,
				})
			);
			setAutoHide();
		},
		[errorMessage, errorTitle, setAutoHide, tabIndex]
	);

	useEffect(() => {
		if (isFresh && !hideSuccessAlert) {
			handleSetSuccessMessages(createdMessage);
			showMessage();
			setIsFresh(false);
			setLocationState({ ...locationState, fresh: false });
		}
	}, [
		handleSetSuccessMessages,
		createdMessage,
		showMessage,
		setLocationState,
		isFresh,
		locationState,
		hideSuccessAlert,
	]);

	const onSubmitWithMessage = (data) => {
		setIsDirty(false);
		if (hideErrorAlert) {
			return onSubmit(data);
		} else {
			setIsSending(true);
			return onSubmit(data)
				.then((/* response */) => {
					if (!hideSuccessAlert) {
						handleSetSuccessMessages();
						showMessage();
					}
				})
				.catch((e) => {
					handleSetErrorMessages();
					showMessage();
					throw e;
				})
				.finally(() => {
					setIsSending(false);
					scrollToTop();
					reset(data, {
						isDirty: false,
						dirtyFields: false,
					});
				});
		}
	};

	const stateMessage = useMemo(() => {
		const tabState = tabSubmitState[tabIndex] ?? {};
		const isVisible = tabVisibilityState[tabIndex] ?? false;
		return (
			<FormAlert
				onClose={hideMessage}
				title={tabState.title}
				message={tabState.message}
				variant={tabState.variant}
				isVisible={isVisible}
				mb={4}
			/>
		);
	}, [hideMessage, tabIndex, tabVisibilityState, tabSubmitState]);

	if (!submitMessages) {
		return {
			onSubmitWithMessage: onSubmit,
			afterSubmitMessage: null,
		};
	}

	return { onSubmitWithMessage, stateMessage, isSending };
};

export const useConfirmBeforeLeave = (isDirty, disabled, hasError) => {
	const { t } = useTranslation('common');
	const navigate = useNavigate();
	const { pathname } = useLocation();
	const confirmationCallbackRef = useRef(noop);
	const [showModal, hideModal] = useModal(
		() => (
			<ConfirmModal
				hideModal={hideModal}
				heading={
					hasError
						? t('common.modals.unsavedChanges.errorMessage')
						: t('common.modals.unsavedChanges.warningMessage')
				}
				confirmLabel={t('common.modals.unsavedChanges.confirmLabel')}
				closeLabel={t('common.modals.unsavedChanges.closeLabel')}
				confirmOnClick={
					(/* EVENT */) => {
						enableNavigation();
						confirmationCallbackRef.current();
					}
				}
			/>
		),
		[hasError]
	);
	useEffect(() => {
		const popStateEventHandler = (/* EVENT */) => {
			if (isDirty) {
				showModal();
				confirmationCallbackRef.current = () => {
					window.history.back();
					window.history.forward();
				};
				return false;
			}
		};
		if (!disabled) {
			window.addEventListener('popstate', popStateEventHandler);
			if (isDirty) {
				blockNavigation((location, params) => {
					showModal();
					confirmationCallbackRef.current = () => navigate(location, params);
				});
			}
		}
		return () => {
			window.removeEventListener('popstate', popStateEventHandler);
			enableNavigation();
		};
	}, [navigate, isDirty, showModal, disabled, pathname]);
};

export const FormSubmitContext = createContext();
export const FormDisabledContext = createContext();
export const FormEscapeDisabledContext = createContext();

export const FormDirtyContext = createContext(false);
