import axios from 'axios';

import { createPageableRequest, dataP } from '../../../utils';
import { DIFFICULTIES } from '../../../constants/urls';

export const getPageableDifficulties = createPageableRequest(DIFFICULTIES);

export const createDifficulty = (difficulty) => axios.post(DIFFICULTIES, difficulty).then(dataP);

export const deleteDifficulty = (difficulty) => axios.delete(`${DIFFICULTIES}/${difficulty.id}`);

export const editDifficulty = (difficulty) => axios.put(`${DIFFICULTIES}/${difficulty.id}`, { difficulty }).then(dataP);
