import { groupBy, compose, o, tail, keys, split, any, includes, uniq } from 'ramda';
import { defaultToEmptyArray, isNotNil, joinWithUnderscore } from 'ramda-extension';

import { notIncludes } from '../../../utils';
import { rafiModules, privilegesAction, rafiRoleModules } from '../../../constants';

export const rafiModuleKeys = keys({ ...rafiModules, ...rafiRoleModules });

export const getPrivilegeModuleName = compose(joinWithUnderscore, tail, split('_'));
const getHasPrivilegeForAction = (action) => o(any(includes(action)), defaultToEmptyArray);

const groupPrivilegesByModules = groupBy(getPrivilegeModuleName);

export const nameTranslationMap = {
	[rafiModules.ROLES]: 'Role',
	[rafiModules.TAGS]: 'Štítky',
	[rafiModules.CARDS]: 'Kartičky',
	[rafiModules.DECKS]: 'Balíčky',
	[rafiModules.WORDS]: 'Slovíčka',
	[rafiModules.DICTIONARY]: 'Slovník',
	[rafiModules.USERS]: 'Uživatelé',
	[rafiModules.APP_USERS]: 'Uživatelé mobilní aplikace',
	[rafiModules.TEXTBOOKS]: 'Učebnice',
	[rafiModules.PUBLISHERS]: 'Vydavatelé',
	[rafiModules.DIFFICULTIES]: 'Obtížnosti',
	[rafiModules.WORD_TYPES]: 'Slovní druhy',
	[rafiModules.MULTI_FORM]: 'Tvorba učebnic',
	[rafiModules.LANGUAGES]: 'Podporované jazyky',
	[rafiModules.FEATURED_TEXTBOOK]: 'Doporučené učebnice',
	[rafiRoleModules.RATING]: 'Hodnocení (učebnice a balíčky)',
	[rafiRoleModules.PUBLISHING]: 'Publikování (učebnic a balíčků)',
};

const mapPrivilegesToRole = ({ privileges = [] }) => {
	const groupedPrivileges = groupPrivilegesByModules(privileges);
	return rafiModuleKeys.map((name) => ({
		name,
		translatedName: nameTranslationMap[name],
		view: getHasPrivilegeForAction(privilegesAction.VIEW)(groupedPrivileges[name]),
		create: getHasPrivilegeForAction(privilegesAction.CREATE)(groupedPrivileges[name]),
		delete: getHasPrivilegeForAction(privilegesAction.DELETE)(groupedPrivileges[name]),
		update: getHasPrivilegeForAction(privilegesAction.UPDATE)(groupedPrivileges[name]),
	}));
};

export const mapPrivilegesToTableAcceptableFormat = (data) => ({
	afterCacheString: '{}',
	after: Number.MAX_SAFE_INTEGER,
	data: mapPrivilegesToRole(data),
	totalCount: rafiModuleKeys.length,
});

const privilegeActionKeys = {
	0: privilegesAction.VIEW,
	1: privilegesAction.CREATE,
	2: privilegesAction.DELETE,
	3: privilegesAction.UPDATE,
};

export const transformRoleIntoFormat = ({ name, delete: deleteAction, create, view, update }) =>
	[view, create, deleteAction, update]
		.map((action) => (action ? `${privilegeActionKeys[action]}_${name}` : undefined))
		.filter(isNotNil);

export const rejectOldModulePrivileges = ({ name, delete: deleteAction, create, view, update }, privileges = []) =>
	uniq([
		...[view, create, deleteAction, update]
			.map((action, index) => (action ? `${privilegeActionKeys[index]}_${name}` : undefined))
			.filter(isNotNil),
		...privileges.filter(notIncludes(name)),
	]);
