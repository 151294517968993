import React from 'react';
import { Flex } from 'rebass';
import Loader from 'react-loader-spinner';

import { usePrimaryColor } from '../../theme';

export const FullscreenLoader = () => {
	const primaryColor = usePrimaryColor();
	return (
		<Flex justifyContent="center" alignItems="center" height="100vh" width="100%">
			<Loader type="TailSpin" color={primaryColor} height={40} width={40} />
		</Flex>
	);
};
