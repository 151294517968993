export const LANGUAGES_CODELIST_NAME = 'languages';

export const TRANSLATION_LANGUAGES_CODELIST_NAME = 'translation_languages';

export const WORD_TYPES_CODELIST_NAME = 'word_types';

export const DIFFICULTIES_CODELIST_NAME = 'difficulties';

export const ROLES_CODELIST_NAME = 'roles';

export const PUBLISHERS_CODELIST_NAME = 'publishers';

export const PUBLISHER_AUTHORS_CODELIST_NAME = 'publisher_authors';

export const USERS_CODELIST_NAME = 'users';

export const TAGS_CODELIST_NAME = 'tags';
