import React from 'react';
import PropTypes from 'prop-types';

import { MenuItem } from './MenuItem';
import { useHasAnyVisibleSubItems } from './hooks';

export const Submenu = ({ children, label, icon, toUrl, hideLabel, canNavigate = false}) => {
	const shouldBeVisible = useHasAnyVisibleSubItems(toUrl);
	return (
		<>
			{(shouldBeVisible && !hideLabel) || (canNavigate && shouldBeVisible) ? (
				<MenuItem label={label} icon={icon} toUrl={toUrl} isSubmenu={!canNavigate} hideLabel={hideLabel}/>
			) : null}
			{!hideLabel && children}
		</>
	);
};

Submenu.propTypes = {
	canNavigate: PropTypes.bool,
	children: PropTypes.node,
	hideLabel: PropTypes.bool,
	icon: PropTypes.element,
	label: PropTypes.string.isRequired,
	toUrl: PropTypes.string.isRequired,
};
