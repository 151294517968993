import { useContext, useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import { InteractiveHeadingContext } from './context';

export const useInteractiveHeadingValue = () => {
	const { headingValue } = useContext(InteractiveHeadingContext) || {};
	return headingValue;
};

export const useInteractiveHeadingValueWatcher = (formPropertyName) => {
	const { setHeadingValue } = useContext(InteractiveHeadingContext) || {};
	const { control } = useFormContext();
	const interactiveHeadingValue = useWatch({
		control,
		name: formPropertyName,
	});
	useEffect(() => {
		if (interactiveHeadingValue && setHeadingValue) {
			setHeadingValue(interactiveHeadingValue);
		}
	}, [interactiveHeadingValue, setHeadingValue]);
};
