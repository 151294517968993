import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { assoc, any, values, o } from 'ramda';

import { applyIfNotNil, isNilOrEmpty, isTruthy } from 'ramda-extension';

import { FormSectionContext } from './context';
import { useSafeFormErrorContext } from './hooks';

const hasAnyFieldsWithErrors = o(any(isTruthy), values);

export const FormSectionProvider = memo(({ children, section }) => {
	const [hasError, setHasError] = useState({});
	const { setSectionError } = useSafeFormErrorContext();
	useEffect(() => {
		applyIfNotNil(setSectionError, [section, hasAnyFieldsWithErrors(hasError ?? {})]);
	}, [hasError, section, setSectionError]);
	const setHasErrorForField = useCallback((hasError, name) => setHasError(assoc(name, hasError)), []);
	const value = useMemo(() => ({ hasError, setHasError: setHasErrorForField }), [hasError, setHasErrorForField]);
	if (isNilOrEmpty(section)) {
		return children;
	}
	return <FormSectionContext.Provider value={value}>{children}</FormSectionContext.Provider>;
});

FormSectionProvider.displayName = 'memo(FormSectionProvider)';

FormSectionProvider.propTypes = {
	children: PropTypes.any,
	section: PropTypes.string.isRequired,
};
