import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { equals } from 'ramda';
import { useModal } from 'react-modal-hook';

import { AuthContext } from './authContext';
import { PERSIST_AUTH_KEY } from './constants';
import { isNotNilOrEmpty } from '../../utils';
import { ConfirmModal } from '../../components/Modal/ConfirmModal';
import { getRole } from '../../api';
import { fiveMinutesMs } from '../../constants';
import { setAxiosAuthorizationRequestInterceptor, setAxiosAuthorizationResponseInterceptor } from '../../api/config';

export const AuthProvider = ({ children }) => {
	const [credentials, setCredentials] = useState(null);

	const [showModal, hideModal] = useModal(
		() => (
			<ConfirmModal
				confirmLabel="Zavřít"
				heading="Práva u vaší role, byla změněna. Budete odhlášeni."
				confirmOnClick={
					(/* EVENT */) => {
						setCredentials(null);
						hideModal();
					}
				}
				hideCloseButton
			/>
		),
		[]
	);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const onVisibilityChange = async () => {
		if (credentials?.roleId) {
			const role = await getRole(credentials?.roleId);
			if (!equals(role.privileges, credentials.privileges)) {
				showModal();
			}
		}
	};

	useEffect(() => {
		const roleLoader = setInterval(() => {
			onVisibilityChange();
		}, fiveMinutesMs);
		return () => clearInterval(roleLoader);
		// eslint-disable-next-line
	}, [credentials]);

	useEffect(() => {
		try {
			const credentials = JSON.parse(localStorage.getItem(PERSIST_AUTH_KEY));
			if (!credentials?.userId) {
				localStorage.removeItem(PERSIST_AUTH_KEY);
				setCredentials(null);
			} else {
				setAxiosAuthorizationResponseInterceptor(setCredentials);
				setAxiosAuthorizationRequestInterceptor(credentials);
				setCredentials(credentials);
			}
		} catch (e) {
			localStorage.removeItem(PERSIST_AUTH_KEY);
			setCredentials(null);
		}
	}, []);
	useEffect(() => {
		if (isNotNilOrEmpty(credentials)) {
			// setAxiosAuthorizationResponseInterceptor(setCredentials);
			localStorage.setItem(PERSIST_AUTH_KEY, JSON.stringify(credentials));
		}
	}, [credentials]);
	const value = useMemo(() => ({ credentials, setCredentials }), [credentials]);
	return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

AuthProvider.propTypes = {
	children: PropTypes.node,
};
