import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'ramda-extension';
import { MdChevronRight } from 'react-icons/md';
import { Flex, Text, Box } from 'rebass';
import { Link } from '@reach/router';
import { SvgIcon } from '../Icon';
import { useIsCurrent } from './hooks';
import { useHasForModuleAction } from '../../features/authorization/hooks';
import { privilegesAction } from '../../constants';

export const SubmenuItem = ({ label, module, toUrl, space, disabled = false, callback = noop }) => {
	const isVisible = useHasForModuleAction(module, privilegesAction.VIEW);
	const isCurrent = useIsCurrent(toUrl, disabled);

	if (!isVisible) {
		return null;
	}

	if (space) {
		return <Box py="10px" />;
	}

	const textColor = disabled ? 'gray.400' : isCurrent ? 'primary' : 'textPrimary';
	const iconColor = disabled ? 'gray.300' : isCurrent ? 'primary' : 'gray.400';
	const hoverColor = disabled ? 'gray.400' : 'primary';
	const cursor = disabled ? 'default' : 'pointer';

	const submenuItemContent = (
		<Flex
			ml={70}
			alignItems="center"
			pb={1}
			pt={1}
			height={30}
			sx={{
				cursor,
				'&:hover .Label': {
					color: hoverColor,
				},
				'&:hover svg': {
					fill: hoverColor,
				},
			}}
		>
			<SvgIcon mr={24} color={iconColor} size={20}>
				<MdChevronRight />
			</SvgIcon>
			<Text className="Label" fontSize={12} width="65%" color={textColor}>
				{label}
			</Text>
		</Flex>
	);
	return !disabled ? (
		<Link to={toUrl} onClick={callback} data-cy={module}>
			{submenuItemContent}
		</Link>
	) : (
		submenuItemContent
	);
};

SubmenuItem.propTypes = {
	callback: PropTypes.func,
	disabled: PropTypes.bool,
	label: PropTypes.string.isRequired,
	module: PropTypes.string,
	space: PropTypes.bool,
	toUrl: PropTypes.string.isRequired,
};
