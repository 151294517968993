import React from 'react';
import PropTypes from 'prop-types';
import { Box, Flex } from 'rebass';
import { validateWysiwyg } from '../../../utils';
import { BASE_LANGUAGE } from '../../../constants';
import { DictionaryInput } from '../../../features/dictionary/components/DictionaryControlledInput';
import { ControlledField } from '../ControlledField';
import { TextField } from '../../../components/Forms';
import { useBaseLanguageId } from '../../../features/codelist';

export const TranslationField = ({
	required,
	labelProps,
	fieldName,
	label,
	component: Component,
	placeholder,
	fieldDefaultValue,
	disabled,
	onAnyFocus,
	onAnyBlur,
	firstFieldAutoFocus,
	secondaryFieldName,
	secondaryFieldComponent = TextField,
	secondaryLabel,
}) => {
	const { id } = useBaseLanguageId();

	return (
		<Flex>
			<Box width={secondaryFieldName ? 0.5 : 1}>
				<ControlledField
					rules={{
						required: required,
						validate: required ? validateWysiwyg : null,
					}}
					labelProps={labelProps}
					name={fieldName}
					label={label}
					component={Component}
					placeholder={placeholder}
					defaultValue={fieldDefaultValue}
					disabled={disabled}
					readOnly={disabled}
					onFocus={onAnyFocus}
					onBlur={onAnyBlur}
					autoFocus={firstFieldAutoFocus}
					languageCode={BASE_LANGUAGE}
					inputElement={DictionaryInput}
					data-cy={`${fieldName}_0`}
				/>
			</Box>
			{secondaryFieldName && (
				<Box width={0.5} ml={25}>
					<Flex>
						<Box width={1}>
							<ControlledField
								name={secondaryFieldName}
								component={secondaryFieldComponent}
								label={secondaryLabel}
								disabled={disabled}
								readOnly={disabled}
								inputElement={DictionaryInput}
								data-cy={`${secondaryFieldName}_0`}
								languageId={id}
								languageLabel={BASE_LANGUAGE}
							/>
						</Box>
					</Flex>
				</Box>
			)}
		</Flex>
	);
};

TranslationField.propTypes = {
	component: PropTypes.string,
	disabled: PropTypes.string,
	fieldDefaultValue: PropTypes.string,
	fieldName: PropTypes.string,
	firstFieldAutoFocus: PropTypes.string,
	label: PropTypes.string,
	labelProps: PropTypes.string,
	onAnyBlur: PropTypes.string,
	onAnyFocus: PropTypes.string,
	placeholder: PropTypes.string,
	required: PropTypes.string,
	secondaryFieldComponent: PropTypes.node,
	secondaryFieldName: PropTypes.string,
	secondaryLabel: PropTypes.string,
};
